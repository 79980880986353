import { initializeApp } from "firebase/app";
import {getMessaging} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAJibpT4cDgnL4veW52PlqXDf9yfwsvt58",
    authDomain: "izostik-dece6.firebaseapp.com",
    projectId: "izostik-dece6",
    storageBucket: "izostik-dece6.appspot.com",
    messagingSenderId: "1044019378223",
    appId: "1:1044019378223:web:490632eb46d29cf7d7aeda",
    measurementId: "G-DZHKYS73EN"
}


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

