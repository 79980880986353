import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Image, Button, Text, Row, Spacer } from "@nextui-org/react";
import { baseUrl } from '../../sevices/API_URL';


function Single_izostik_info(props) {

    const navigate = useNavigate()

    const {id} = useParams()
    const idMain = id.replaceAll("_", "/")

    return (
        <div>
            <Spacer y={2}/>
            <Row Display="flex" justify="center">
                <Button onClick={() => navigate(-1)}  >Go Back</Button>
            </Row>
            <Spacer y={2}/>
            <Row Display="flex" justify="center">
                <Text color="warning" >
                    Almost before we knew it, we had left the ground.
                </Text>
            </Row>
            <Spacer y={2}/>
            <Image
                width={800}
                height={400}  
                src={`${baseUrl}/file${idMain}`}
                alt="Default Image"
                objectFit="cover"
            />
        </div>
    );
}

export default Single_izostik_info;