
import { useEffect, useState } from "react";
import { Table, Row, Col, Tooltip, User, Text, Button, Spacer, Container, Modal } from "@nextui-org/react";
import { IconButton } from "../superAdminAdmins/IconButton";
import { EditIcon } from "../superAdminAdmins/EditIcon";
import { DeleteIcon } from "../superAdminAdmins/DeleteIcon";
import AddOrganizationModal from "./AddOrganizationModal";
import { useHttp } from "../../../sevices/useHttp";
import Paginationnnnn from "../../pagination/Paginationnnnn";
import { useSelector, useDispatch } from "react-redux";
import { allAdminsList, deletedAdmin } from "../../../redux/superAdminSlice";
import momentTimezone from "moment-timezone"
import { API_URL_delete_super_org, API_URL_get_super_org, API_URL_post_super_org } from "../../../sevices/API_URL";
import { useSearchParams } from "react-router-dom";
import LoadingPage from "../../../loading/LoadingPage";
import { baseUrl } from "../../../sevices/API_URL";
import DeleteMoadal from "../../modals/DeleteMoadal";
import EditModalOrg from "../../modals/EditModalOrg";


export default function SuperAdminOrganization() {

    const {request} = useHttp()
    const dispatch = useDispatch()
    const {allAdmins} = useSelector(state => state.superAdmin)
    const [visible, setVisible] = useState(false); 
    const [searchParams, setSearrchParams] = useSearchParams()
    let initialValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const [lenth, setLength] = useState("")
    const [status, setStatus] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [getId, setgGetId] = useState("")

    
    const handler = () => setVisible(true)
    const closeHandler = () => {
        setVisible(false);
    };

    const showDeleteModal = (id) => {
        setShowModal(!showModal)
        setgGetId(id)
    }

    const showEditModalFunc = (id) => {
        setShowEditModal(!showEditModal)
        setgGetId(id)
    }

    // Post Organization----------------------------------------------------

    const onSubmit = (obj) => {
        request(API_URL_post_super_org, "POST", JSON.stringify(obj),{
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        }).then(response => {
            getAllOrganization()
        }).catch((err) => {
            console.log(err);
        })
    };

    // Get Organization----------------------------------------------------

    const getAllOrganization = () => {
        request(`${API_URL_get_super_org}?page=${initialValue}&limit=10`, "GET", null, {
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        })
        .then(response => {
            dispatch(allAdminsList(response.items))
            setLength(response.meta)
            setStatus(response?.status)
        })
        .catch((err) => {
            console.log(err);
        })
    }
    
    useEffect(() => {
        getAllOrganization()
    }, [initialValue]) 

// Delete Organization-----------------------------------------------

const onDelete = (id) => {
    request(`${API_URL_delete_super_org}/${id}`, "DELETE", null, {
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
    }).then(response => {
        dispatch(deletedAdmin(id))
    }).catch(err => {
        console.log(err);
    })
    setShowModal(!showModal)
}

    const columns = [
        { name: "KORXONA NOMI", uid: "name" },
        { name: "YARATILGAN VAQTI", uid: "created_at" },
        { name: "ACTIONS", uid: "actions" },
    ];
    const users = allAdmins;
    const renderCell = (user, columnKey) => {
        const cellValue = user[columnKey];
        switch (columnKey) {
            case "name":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} >
                            {cellValue}
                            </Text>
                        </Row>
                    </Col>
                );
                case "created_at":
                    return (
                        <Col>
                            <Row>
                                <Text b size={14} >
                                {momentTimezone(cellValue).tz('Asia/Tashkent').format('DD.MM.YYYY HH:mm')}
                                </Text>
                            </Row>
                        </Col>
                    );

            case "actions":
                return (
                    <Row justify="center" align="center">
                        <Col css={{ d: "flex" }}>
                            <Tooltip content="Edit user">
                                <IconButton onClick={() => showEditModalFunc(user?.id)}>
                                    <EditIcon size={20} fill="#979797" />
                                </IconButton>
                            </Tooltip>
                        </Col>
                        <Col css={{ d: "flex" }}>
                            <Tooltip
                                content="Delete user"
                                color="error"
                                onClick={() => showDeleteModal(user?.id)}
                            >
                                <IconButton >
                                    <DeleteIcon size={20} fill="#FF0080" />
                                </IconButton>
                            </Tooltip>
                        </Col>
                    </Row>
                );
            default:
                return cellValue;
        }
    };
    if(status === 200){
        return (
            <Container lg>
                {showEditModal ? <EditModalOrg setShowEditModal={setShowEditModal} id={getId} showEditModal={showEditModal} getAllOrganization={getAllOrganization}/> :null}
                {showModal ? <DeleteMoadal onDelete={() => onDelete(getId)} setShowModal={setShowModal}/> : null}
                <AddOrganizationModal
                    visible={visible}
                    closeHandler={closeHandler}
                    onSubmit={onSubmit}
                />
                <Spacer y={1} />
                <Row type Display="flex" justify="flex-end">
                    <Button shadow color="primary" auto onClick={handler}>
                        MTU yaratish
                    </Button>
                </Row>
                <Spacer y={1} />
                <Row >
                    <Col>
                        <Table
                            aria-label="Example table with custom cells"
                            css={{
                                height: "auto",
                                minWidth: "100%",
                            }}
                            selectionMode="none"
                        >
                            <Table.Header columns={columns}>
                                {(column) => (
                                    <Table.Column
                                        key={column.uid}
                                        hideHeader={column.uid === "actions"}
                                        align={column.uid === "actions" ? "center" : "start"}
                                    >
                                        {column.name}
                                    </Table.Column>
                                )}
                            </Table.Header>
                            <Table.Body items={users}>
                                {(item) => (
                                    <Table.Row>
                                        {(columnKey) => (
                                            <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                                        )}
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Col>
                </Row>
                <Spacer y={1}/>
                <Row >
                    <Col >
                        <Paginationnnnn setSearrchParams={setSearrchParams} initialValue={initialValue} totalPage={lenth.totalPages}/>
                    </Col>
                </Row>
            </Container>
        );
    }else {
        return(
            <LoadingPage/>
        )
    }
}