
import { useEffect } from "react";
import { Table, Row, Col, Tooltip, User, Text, Button, Spacer, Container } from "@nextui-org/react";
import { StyledBadge } from "./StyledBadge";
import { IconButton } from "./IconButton";
import { EyeIcon } from "./EyeIcon";
import { EditIcon } from "./EditIcon";
import { DeleteIcon } from "./DeleteIcon";
import { useState } from "react";
import AddAdminModal from "../superAdmin_AddAdmin/AddAdminModal";
import { useHttp } from "../../../sevices/useHttp";
import { useSearchParams } from "react-router-dom";
import Paginationnnnn from "../../pagination/Paginationnnnn";
import { API_URL_post_super_admin, API_URL_get_super_admin } from "../../../sevices/API_URL";
import LoadingPage from "../../../loading/LoadingPage";
import { baseUrl } from "../../../sevices/API_URL";
import { allAdminsList, deletedAdmin } from "../../../redux/superAdminSlice";
import DeleteMoadal from "../../modals/DeleteMoadal";
import { useDispatch, useSelector } from "react-redux";
import EditModal from "../../modals/EditModal";

export default function SuperAdmin() {

    const [searchParams, setSearrchParams] = useSearchParams()
    let initialValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const [lenth, setLength] = useState("")


    const {request} = useHttp()
    const dispatch = useDispatch();
    const {allAdmins} = useSelector(state => state.superAdmin)
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [getId, setgGetId] = useState("")


    const handler = () => setVisible(true)

    const closeHandler = () => {
        setVisible(false);
    };

    const showDeleteModal = (id) => {
        setShowModal(!showModal)
        setgGetId(id)
    }

    const showEditModalFunc = (id) => {
        setShowEditModal(!showEditModal)
        setgGetId(id)
    }

    // Post Admin========================================================================
    const onSubmit = (obj) => {
        request(API_URL_post_super_admin, "POST", JSON.stringify(obj),{
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        }).then(response => {
            gettAllAdmins()
        }).catch((err) => {
            console.log(err);
        })
    };

    // Get Admin=========================================================================

    const gettAllAdmins = () => {
        request(`${API_URL_get_super_admin}?page=${initialValue}&limit=10`, "GET", null, {
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        })
        .then(response => {
            dispatch(allAdminsList(response?.items))
            setLength(response?.meta)
            setStatus(response?.status)
        })
        .catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        gettAllAdmins()
    }, [initialValue]) 

     // Delete Admin========================================================================
    const onDelete = (id) => {
        request(`${baseUrl}/api/user/delete/${id}`, "DELETE", null,{
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        }).then(response => {
            dispatch(deletedAdmin(id))
        }).catch((err) => {
            console.log(err);
        })
        setShowModal(!showModal)
    };


    const columns = [
        { name: "NAME", uid: "full_name" },
        { name: "USER NAME", uid: "username" },
        { name: "STATUS", uid: "role" },
        { name: "ACTIONS", uid: "actions" },
    ];
    const users = allAdmins;
    const renderCell = (user, columnKey) => {
        const cellValue = user[columnKey];
        switch (columnKey) {
            case "full_name":
                return (
                    <User squared src={`${baseUrl}/file${user?.__file__?.url_2}`} name={cellValue} css={{ p: 0 }}>
                    </User>
                );
            case "username":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} css={{ tt: "capitalize" }}>
                                {cellValue}
                            </Text>
                        </Row>
                    </Col>
                );
            case "role":
                return <StyledBadge type={user.role}>{cellValue}</StyledBadge>;

            case "actions":
                return (
                    <Row justify="center" align="center">
                        <Col css={{ d: "flex" }}>
                            <Tooltip content="Details">
                                <IconButton onClick={() => console.log("View user", user.id)}>
                                    <EyeIcon size={20} fill="#979797" />
                                </IconButton>
                            </Tooltip>
                        </Col>
                        <Col css={{ d: "flex" }}>
                            <Tooltip content="Edit user">
                                <IconButton onClick={() => showEditModalFunc(user?.id)}>
                                    <EditIcon size={20} fill="#979797" />
                                </IconButton>
                            </Tooltip>
                        </Col>
                        <Col css={{ d: "flex" }}>
                            <Tooltip
                                content="Delete user"
                                color="error"
                                onClick={() => showDeleteModal(user?.id)}
                            >
                                <IconButton>
                                    <DeleteIcon size={20} fill="#FF0080" />
                                </IconButton>
                            </Tooltip>
                        </Col>
                    </Row>
                );
            default:
                return cellValue;
        }
    };
    if(status === 200){
        return (
            <Container lg >
                {showEditModal ? <EditModal setShowEditModal={setShowEditModal} id={getId} showEditModal={showEditModal} gettAllAdmins={gettAllAdmins}/> :null}
                {showModal ? <DeleteMoadal onDelete={() => onDelete(getId)} setShowModal={setShowModal}/> : null}
                <AddAdminModal
                    visible={visible}
                    closeHandler={closeHandler}
                    onSubmit={onSubmit}
                />
                <Spacer y={1} />
                <Row type Display="flex" justify="flex-end">
                    <Button shadow color="primary" auto onClick={handler}>
                        Admin yaratish
                    </Button>
                </Row>
                <Spacer y={1} />
                <Row >
                    <Col>
                        <Table
                            aria-label="Example table with custom cells"
                            css={{
                                height: "auto",
                                minWidth: "100%",
                            }}
                            selectionMode="none"
                        >
                            <Table.Header columns={columns}>
                                {(column) => (
                                    <Table.Column
                                        key={column.uid}
                                        hideHeader={column.uid === "actions"}
                                        align={column.uid === "actions" ? "center" : "start"}
                                    >
                                        {column.name}
                                    </Table.Column>
                                )}
                            </Table.Header>
                            <Table.Body items={users}>
                                {(item) => (
                                    <Table.Row>
                                        {(columnKey) => (
                                            <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                                        )}
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Col>
                </Row>
                <Spacer y={1}/>
                <Row >
                    <Col >
                        <Paginationnnnn setSearrchParams={setSearrchParams} initialValue={initialValue} totalPage={lenth.totalPages}/>
                    </Col>
                </Row>
            </Container>
        );
    }else {
        return(
            <LoadingPage/>
        )
    }
}