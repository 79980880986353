const API_URL_login = `https://izostik.qarshimtu.uz/api/auth/signin`

const baseUrl = "https://izostik.qarshimtu.uz"

const API_URL_post_super_admin = "https://izostik.qarshimtu.uz/api/user/create"
const API_URL_get_super_admin = "https://izostik.qarshimtu.uz/api/user/find/all"
const API_URL_post_super_org = "https://izostik.qarshimtu.uz/api/organization/create"
const API_URL_get_super_org = "https://izostik.qarshimtu.uz/api/organization/find/all"
const API_URL_delete_super_org = "https://izostik.qarshimtu.uz/api/organization/delete"
const API_URL_get_org_list = "https://izostik.qarshimtu.uz/api/organization/find/list"
const API_URL_post_super_moderator ="https://izostik.qarshimtu.uz/api/moderator/create"
const API_URL_get_super_moderator = "https://izostik.qarshimtu.uz/api/moderator/find/all"
const API_URL_delete_super_moderator = "https://izostik.qarshimtu.uz/api/moderator/delete"

const API_URL_post_station = "https://izostik.qarshimtu.uz/api/station/create"
const API_URL_get_station = "https://izostik.qarshimtu.uz/api/station/find/all"
const API_URL_delete_station = "https://izostik.qarshimtu.uz/api/organization/delete"
const API_URL_moderator_post_shch_admin = "https://izostik.qarshimtu.uz/api/admin/create"
const API_URL_moderator_get_shch_admin = "https://izostik.qarshimtu.uz/api/admin/find/all"
const API_URL_moderator_get_shch_list = "https://izostik.qarshimtu.uz/api/organization-child/find/list"
const API_URL_moderator_post_pch_admin ="https://izostik.qarshimtu.uz/api/admin-pch/create"
const API_URL_moderator_get_pch_admin = "https://izostik.qarshimtu.uz/api/admin-pch/find/all"
const API_URL_moderator_get_pch_list = "https://izostik.qarshimtu.uz/api/pch-organization/find/list"
const API_URL_moderator_post_pch = "https://izostik.qarshimtu.uz/api/pch-organization/create"
const API_URL_moderator_get_pch= "https://izostik.qarshimtu.uz/api/pch-organization/find/all"
const API_URL_moderator_get_station_list="https://izostik.qarshimtu.uz/api/station/find/list"
const API_URL_moderator_get_pch_view="https://izostik.qarshimtu.uz/api/pch-organization/view"
const API_URL_moderator_post_shch= "https://izostik.qarshimtu.uz/api/organization-child/create"
const API_URL_moderator_get_shch="https://izostik.qarshimtu.uz/api/organization-child/find/all"
const API_URL_moderator_get_shch_view="https://izostik.qarshimtu.uz/api/organization-child/view"

const API_URL_pchAdmin_post_employee = "https://izostik.qarshimtu.uz/api/employee-pch/create"
const API_URL_pchAdmin_get_employee = "https://izostik.qarshimtu.uz/api/employee-pch/find/all"
const API_URL_pchAdmin_get_station = "https://izostik.qarshimtu.uz/api/stick/station/list"
const API_URL_pchAdmin_post_Izostik = "https://izostik.qarshimtu.uz/api/stick/create"
const API_URL_pchAdmin_get_Izostik = "https://izostik.qarshimtu.uz/api/stick/find/all"
const API_URL_pchAdmin_get_station_pch = "https://izostik.qarshimtu.uz/api/employee-pch/station/list"

const API_URL_employee_pch_get_all_izostik = "https://izostik.qarshimtu.uz/api/employee-stick-pch/find/all"
const API_URL_employee_pch_get_all_statistics = "https://izostik.qarshimtu.uz/api/employee-stick-pch/find/statist"
const API_URL_employee_pch_get_all_status_izostik = "https://izostik.qarshimtu.uz/api/employee-stick-pch/find/status"







export {
    baseUrl,
    API_URL_post_super_admin,
    API_URL_get_super_admin,
    API_URL_post_super_org,
    API_URL_get_super_org,
    API_URL_delete_super_org,
    API_URL_delete_super_moderator,
    API_URL_get_super_moderator,
    API_URL_post_super_moderator,
    API_URL_login, 
    API_URL_post_station, 
    API_URL_get_station,
    API_URL_delete_station,
    API_URL_moderator_post_shch_admin,
    API_URL_moderator_get_shch_admin,
    API_URL_moderator_get_shch_list,
    API_URL_moderator_post_pch_admin,
    API_URL_moderator_get_pch_admin,
    API_URL_moderator_get_pch_list,
    API_URL_moderator_post_pch,
    API_URL_moderator_get_pch,
    API_URL_moderator_get_station_list,
    API_URL_moderator_get_pch_view,
    API_URL_moderator_get_shch_view,
    API_URL_moderator_get_shch,
    API_URL_moderator_post_shch,
    API_URL_pchAdmin_post_employee,
    API_URL_pchAdmin_get_employee,
    API_URL_pchAdmin_get_station, 
    API_URL_pchAdmin_post_Izostik,
    API_URL_pchAdmin_get_Izostik,
    API_URL_pchAdmin_get_station_pch,
    API_URL_employee_pch_get_all_status_izostik,
    API_URL_employee_pch_get_all_statistics,
    API_URL_employee_pch_get_all_izostik,
    API_URL_get_org_list
}