import React, { useState, useEffect, useMemo } from "react";
import { Modal, Input, Row, Checkbox, Button, Text,Dropdown } from "@nextui-org/react";
import { UserName } from "../../superAdmin/superAdmin_AddAdmin/UserName";
import { useHttp } from "../../../sevices/useHttp";
import {API_URL_moderator_get_station_list }from "../../../sevices/API_URL"

export default function AddChildOrgModal(props) {

    const {request} = useHttp()
    const { visible, closeHandler, onSubmit } = props;
    const [name, setName] = useState("");
    const [selected, setSelected] = useState([]);
    const [allStation, setAllStation] = useState([]);

    const selectedValue = useMemo(
        () => Array.from(selected).join(", "),
        [selected]
    );

    const select =selectedValue.split(", ");

    const handlerSubmit = () => {
        const obj = {
            name,
            station_list:select,
        }   
        onSubmit(obj)
        closeHandler()
    };

// Get Station List ==============================================================================

    useEffect(() => {
        request(API_URL_moderator_get_station_list, "GET", null, {
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        })
        .then(response => {
            setAllStation(response.data)
        })
        .catch((err) => {
            console.log(err);
        })
    }, []) 

    return (
        <div>
            <Modal
                closeButton
                blur
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
            >
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        PCH Qo'shish
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Organization Name"
                        contentLeft={<UserName fill="currentColor" />}
                    />

                    <Dropdown>
                        <Dropdown.Button flat color="secondary" css={{ tt: "capitalize" }}>
                            {selectedValue.split(",").length}
                        </Dropdown.Button>
                        <Dropdown.Menu
                            aria-label="Multiple selection actions"
                            color="secondary"
                            disallowEmptySelection
                            selectionMode="multiple"
                            selectedKeys={selected}
                            onSelectionChange={setSelected}
                        >
                            {allStation?.length ? allStation.map((item) => (
                                <Dropdown.Item key={item.id}>{item.name}</Dropdown.Item>
                            )) : null}
                        </Dropdown.Menu>
                    </Dropdown>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat color="error" onClick={closeHandler}>
                        Bekor qilish
                    </Button>
                    <Button auto onClick={handlerSubmit}>
                        Saqlash
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}