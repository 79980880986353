
import { useEffect } from "react";
import { Table, Row, Col, Tooltip, User, Text, Button, Spacer, Container, Avatar, css } from "@nextui-org/react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useHttp } from "../../sevices/useHttp";
import Paginationnnnn from "../pagination/Paginationnnnn"
import { API_URL_pchAdmin_get_Izostik} from "../../sevices/API_URL";
import momentTimezone from "moment-timezone"
import { allIzostik } from "../../redux/adminPchSlice";
import { useSearchParams } from "react-router-dom";
import { baseUrl } from "../../sevices/API_URL";
import LoadingPage from "../../loading/LoadingPage";


export default function Izostik_SHCH() {

    const { request } = useHttp()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { all_Izostik } = useSelector(state => state.pch_admins)
    const [searchParams, setSearrchParams] = useSearchParams()
    let   initialValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const [lenth, setLength] = useState("")
    const [status, setStatus] = useState("")
    

    // Get Izostik For Pch=====================================================================

    const gettAllIzostik = () => {
        request(`${API_URL_pchAdmin_get_Izostik}?page=${initialValue}&limit=10`, "GET", null, {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json"
        })
            .then(response => {
                dispatch(allIzostik(response.items))
                setLength(response.meta)
                setStatus(response?.status)
            })
            .catch((err) => {
                console.log(err);
            })
    }
    useEffect(() => {
        gettAllIzostik()
    }, [initialValue])

    // console.log(allAdmin)



    const columns = [
        { name: "STANSIYA NOMI", uid: "__station__" },
        { name: "NOMI", uid: "name" },
        { name: "RELS TURI", uid: "rail_type" },
        { name: "JOYLASHGAN JOYI", uid: "location" },
        { name: "YO'LNING TURI", uid: "road" },
        { name: "KO'RIKDAN O'TKAZILGAN SANA", uid: "start_date" },
        { name: "KEYINGI KO'RIKDAN O'TKAZISH SANASI", uid: "finish_date" },
        { name: "STATUS", uid: "status" },
        { name: "RASM", uid: "file" },
        { name: "ACTIONS", uid: "actions" },
    ];
    const users = all_Izostik;

    const renderCell = (user, columnKey) => {
        const cellValue = user[columnKey];
        switch (columnKey) {
            case "__station__":
                return (
                    <Col>
                        <Row >
                            <Text b size={14} >
                                {cellValue.name}
                            </Text>
                        </Row>
                    </Col>
                );
            case "name":
                return (
                    <Col>
                        <Row >
                            <Text b size={14} >
                                {cellValue}
                            </Text>
                        </Row>
                    </Col>
                );
            case "rail_type":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} >
                                {cellValue}
                            </Text>
                        </Row>
                    </Col>
                );
            case "location":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} >
                                {cellValue}
                            </Text>
                        </Row>
                    </Col>
                );
            case "road":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} >
                                {cellValue === "MAIN" ? "ASOSIY YO'L" : "QO'SHIMCHA YO'L"}
                            </Text>
                        </Row>
                    </Col>
                );
            case "start_date":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} >
                                {momentTimezone(cellValue).tz('Asia/Tashkent').format('DD.MM.YYYY')}
                            </Text>
                        </Row>
                    </Col>
                );
            case "finish_date":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} >
                                {momentTimezone(cellValue).tz('Asia/Tashkent').format('DD.MM.YYYY')}
                            </Text>
                        </Row>
                    </Col>
                );
                case "status":
                    return (
                        <Col>
                            <Row>
                                <Text b size={14} >
                                    {cellValue === "EDIT_PCH" ? "SHCH ishini tugatmadi" : cellValue === "EDIT_SHCH" ? "PCH ishini tugatmadi" : null}
                                </Text>
                            </Row>
                        </Col>
                    );
            case "file":
                return (
                    <Col>
                        <Row>
                            <Text b size={14}  >
                                <Avatar
                                    //onClick={() => navigate(`/singleIzostikInfo/${cellValue?.url_2?.replaceAll("/", "_")}`)}
                                    size="lg"
                                    src={`${baseUrl}/file${user?.__file__?.url_2}`}
                                    zoomed
                                    css={{ cursor: "pointer" }}
                                />
                            </Text>
                        </Row>
                    </Col>
                );
            default:
                return cellValue;
        }
    };
    if(status === 200){
        return (
            <Container lg>
                <Row >
                    <Col>
                        <Table
                            aria-label="Example table with custom cells"
                            css={{
                                height: "auto",
                                minWidth: "100%",
                            }}
                            selectionMode="none"
                        >
                            <Table.Header columns={columns}>
                                {(column) => (
                                    <Table.Column
                                        key={column.uid}
                                        hideHeader={column.uid === "actions"}
                                        align={column.uid === "actions" ? "center" : "start"}
                                    >
                                        {column.name}
                                    </Table.Column>
                                )}
                            </Table.Header>
                            <Table.Body items={users}>
                                {(item) => (
                                    <Table.Row css={item.status === "WHITE" ? { background: '$white' } : item.status === "RED" ? { background: '#F75F94' } : item.status === "EDIT_SHCH" || item.status === "EDIT_PCH" ? { background: '#0072F5' } : { background: '#F8C572' }}>
                                        {(columnKey) => (
                                            <Table.Cell >{renderCell(item, columnKey)}</Table.Cell>
                                        )}
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Col>
                </Row>
                <Spacer y={1}/>
                <Row >
                    <Col >
                        <Paginationnnnn setSearrchParams={setSearrchParams} initialValue={initialValue} totalPage={lenth.totalPages}/>
                    </Col>
                </Row>
            </Container>
        );
    }else{
        return(
            <LoadingPage/>
        )
    }
}