import React, { useState } from "react";
import { Modal, Button, Text } from "@nextui-org/react";
import { baseUrl } from "../../sevices/API_URL";

export default function ChangeAvatar({ visible1, setVisible1 }) {


  const [file, setFile] = useState("");

  const closeHandler = () => {
    setVisible1(false);
  };

  const onSubmit = async () => {
    let formData = new FormData();
    formData.append("file", file);
    const response = await fetch(`${baseUrl}/api/user/avatar`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        //"Content-type":"application/json"
      },
    });
    const data = await response.json();
    closeHandler()
  };

  return (
      <div>
        <Modal
          closeButton
          blur
          aria-labelledby="modal-title"
          open={visible1}
          onClose={closeHandler}
        >
          <Modal.Header>
            <Text id="modal-title" b size={18}>
              Profil rasmini o'zgartirish
            </Text>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label for="formFile" className="form-label">
                Fayl biriktirish
              </label>
              <input
                onChange={(e) => setFile(e.target.files[0])}
                className="form-control"
                type="file"
                id="formFile"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button auto flat color="error" onClick={closeHandler}>
              Bekor qilish
            </Button>
            <Button auto onClick={onSubmit}>
              Saqlash
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
  );
}

