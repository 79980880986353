import React, { useEffect, useState } from "react";
import {useSearchParams, useNavigate, useLocation} from "react-router-dom"
import { useHttp } from "../../sevices/useHttp";
import { baseUrl } from "../../sevices/API_URL";
import { Table, Row, Col, Container, Text, Spacer, Button, Input   } from "@nextui-org/react";
import momentTimezone from "moment-timezone";
import LoadingPage from "../../loading/LoadingPage";
import Paginationnnnn from "../pagination/Paginationnnnn";

export default function IzostikWhite() {
  

    const {state} = useLocation();

    const [searchParams, setSearrchParams] = useSearchParams();
    let initialValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const { request } = useHttp();
    const navigate = useNavigate()
    const [whiteStik, setWhiteStik] = useState(null);
    const [lenth, setLength] = useState("");
    const [valueByStatus, setValueByStatus] = useState(state)

useEffect(() => {
    request(
      `${baseUrl}/api/employee-stick-pch/find/status/page/${valueByStatus}?page=${initialValue}&limit=10`,
      "GET",
      null,
      {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json",
      }
    )
      .then((response) => {
        setWhiteStik(response?.items);
        setLength(response?.meta);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [request, initialValue]);

  const columns = [
    { name: "NAME", uid: "name" },
    { name: "RELS TURI", uid: "rail_type" },
    { name: "JOYLASHGAN JOYI", uid: "location" },
    { name: "YO'L TURI", uid: "road" },
    { name: "KO'RIKDAN O'TKAZILGAN SANA", uid: "start_date" },
    { name: "KEYINGI KO'RIKDAN O'TKAZISH SANASI", uid: "finish_date" },
  ];
  const users = whiteStik;
  const renderCell = (user, columnKey) => {
    const cellValue = user[columnKey];
    switch (columnKey) {
      case "name":
        return (
          <Col>
            <Row>
              <Text b size={14} css={{ tt: "capitalize" }}>
                {cellValue}
              </Text>
            </Row>
          </Col>
        );
      case "location":
        return (
          <Col>
            <Row>
              <Text b size={14} css={{ tt: "capitalize" }}>
                {cellValue}
              </Text>
            </Row>
          </Col>
        );
      case "rail_type":
        return (
          <Col>
            <Row>
              <Text b size={14} css={{ tt: "capitalize" }}>
                {cellValue}
              </Text>
            </Row>
          </Col>
        );
      case "road":
        return (
          <Col>
            <Row>
              <Text b size={14} css={{ tt: "capitalize" }}>
                {cellValue === "MAIN" ? "Asosiy" : "Yon"}
              </Text>
            </Row>
          </Col>
        );
      case "start_date":
        return (
          <Col>
            <Row>
              <Text b size={14} css={{ tt: "capitalize" }}>
                {momentTimezone(cellValue)
                  .tz("Asia/Tashkent")
                  .format("DD.MM.YYYY")}
              </Text>
            </Row>
          </Col>
        );
        case "finish_date":
        return (
          <Col>
            <Row>
              <Text b size={14} css={{ tt: "capitalize" }}>
                {momentTimezone(cellValue)
                  .tz("Asia/Tashkent")
                  .format("DD.MM.YYYY")}
              </Text>
            </Row>
          </Col>
        );
      default:
        return cellValue;
    }
  };
  if(whiteStik){
    return (
        <Container lg>
            <Spacer y={1}/>
          {state === "WHITE" ? <h3>Muddati o'tmagan stiklar</h3> : state === "YELLOW" ? <h3>Muddati yaqinlashgan stiklar</h3> : state === "RED" ?  <h3>Muddati o'tgan stiklar</h3> :  <h3>Ishlar olib borilayotgan stiklar</h3>}
          <Table
            aria-label="Example table with custom cells"
            css={{
              height: "auto",
              minWidth: "100%",
            }}
            selectionMode="none"
          >
            <Table.Header columns={columns}>
              {(column) => (
                <Table.Column
                  key={column.uid}
                  hideHeader={column.uid === "actions"}
                  align={column.uid === "actions" ? "center" : "start"}
                >
                  {column.name}
                </Table.Column>
              )}
            </Table.Header>
            <Table.Body items={users}>
              {(item) => (
                <Table.Row>
                  {(columnKey) => (
                    <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                  )}
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Spacer y={1} />
        <Row>
          <Col css={{display:"flex", justifyContent:"space-between"}}>
            <Paginationnnnn
              setSearrchParams={setSearrchParams}
              initialValue={initialValue}
              totalPage={lenth.totalPages}
            />
            <Button onClick={() => navigate("/employeePchProfile")}>Orqaga</Button>
          </Col>
        </Row>
        </Container>
      );
  }else if(whiteStik === undefined){
    return(
       <div className="text-center mt-5">
        <h4>Bunday turdagi stiklar mavjud emas</h4>
        <button onClick={() => navigate("/employeePchProfile")} className="btn btn-primary">Orqaga</button>
       </div>
    )
  }
  else{
    return(
        <LoadingPage/>
    )
  }
}
