import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { useHttp } from '../../sevices/useHttp';
import { baseUrl } from '../../sevices/API_URL';
import Paginationnnnn from '../pagination/Paginationnnnn';
import momentTimezone from "moment-timezone";
import LoadingPage from '../../loading/LoadingPage';

function WorkDone() {

    const {request} = useHttp()
    const [searchParams, setSearrchParams] = useSearchParams()
    const [lenth, setLength] = useState("")
    const [statusResponse, setStatusResponse] = useState("")
    const [works, setWorks] = useState([])
    
    let   initialValue = searchParams.get("page") ? searchParams.get("page") : 1;

    const gettAllEmployeesPch = () => {
        request(`${baseUrl}/api/works/find/all`, "GET", null, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json",
        })
            .then((response) => {
            setLength(response?.meta)
            setWorks(response?.items)
            setStatusResponse(response?.status)
            console.log(response);
            })
            .catch((err) => {
            console.log(err);
            });
    }

    useEffect(() => {
        gettAllEmployeesPch()
    }, [initialValue]) 


    if(statusResponse){
        return (
            <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", marginTop:"20px"}}>
                <Accordion defaultActiveKey="0" style={{width:"70%", marginBottom:"20px"}} >
                    {works?.length ? works?.map((item, index) => (
                        <Accordion.Item eventKey={index}>
                        <Accordion.Header><strong>{index+1}</strong>. {item?.__station__?.name} / № : {item?.__stick__?.name} /   {momentTimezone(item.createdAt).tz("Asia/Tashkent").format("DD.MM.YYYY")}</Accordion.Header>
                        <Accordion.Body>
                            <h4 className='text-center'>PCH bajargan ishlar bo'yicha ma'lumot</h4>
                            <p><strong>Ismi : </strong>{item?.__pch_user__?.full_name}</p>
                            <p><strong>Lavozimi : </strong>{item?.__pch_user__?.job_name}</p>
                            <p><strong>Telefon raqami : </strong>{item?.__pch_user__?.phone_number}</p>
                            <p><strong>Izoh : </strong>{item?.description_pch}</p>
                            {item?.file_pch?.url_1 === 0 ? <p><strong>File biriktirilmagan </strong></p> : <p><strong>File : </strong><a href={`${baseUrl}/file${item?.file_pch?.url_1}`} download >Download</a></p>}
                        </Accordion.Body>
                        <Accordion.Body>
                            <h4 className='text-center'>SHCH bajargan ishlar bo'yicha ma'lumot</h4>
                            <p><strong>Ismi : </strong>{item?.__sh_user__?.full_name}</p>
                            <p><strong>Lavozimi : </strong>{item?.__sh_user__?.job_name}</p>
                            <p><strong>Telefon raqami : </strong>{item?.__sh_user__?.phone_number}</p>
                            <p><strong>Izoh : </strong>{item?.description_shch}</p>
                            {item?.file_shch?.url_1 === null ? <p><strong>File biriktirilmagan </strong></p> :  <p><strong>File : </strong><a href={`${baseUrl}/file${item?.file_shch?.url_1}`} >Download</a></p>}
                        </Accordion.Body>
                        </Accordion.Item>
                    )) : null}
                </Accordion>
                <Paginationnnnn setSearrchParams={setSearrchParams} initialValue={initialValue} totalPage={lenth?.totalPages}/>
            </div>
    );
    }else{
        return(
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <LoadingPage />
            </div>
        )
    }
    
}

export default WorkDone;
