import { Table } from '@nextui-org/react';
    
export default function ModeratorStikStatistics({statistics}) {

    let data = statistics?.sticks

  return (
    <>
        <h3 className='text-center my-3'>Stansiyalardagi barcha izostiklar haqida ma'lumot</h3>
        <Table
          bordered
          shadow={false}
          color="secondary"
          aria-label="Example pagination  table"
          css={{
            height: "auto",
            minWidth: "100%",
          }}
          selectionMode="multiple"
        >
          <Table.Header>
            <Table.Column>STANSIYA NOMI</Table.Column>
            <Table.Column>MUDDATI O'TMAGANLAR</Table.Column>
            <Table.Column>MUDDATI YAQINLASHGANLAR</Table.Column>
            <Table.Column>MUDDATI O'TGANLAR</Table.Column>
            <Table.Column>ISHLAR OLIB BORILAYOTGANLSR</Table.Column>
          </Table.Header>
          <Table.Body >
            {data?.length ? data.map((item, index) => (
                <Table.Row key={index}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.white}</Table.Cell>
                <Table.Cell css={{color:"#F5A524"}}>{item.yellow}</Table.Cell>
                <Table.Cell css={{color:"#F31260"}}>{item.red}</Table.Cell>
                <Table.Cell css={{color:"#0072F5"}}>{item.works}</Table.Cell>
               </Table.Row>
            )) : null}
          </Table.Body>
          <Table.Pagination
            shadow
            noMargin
            align="center"
            rowsPerPage={5}
            onPageChange={(page) => console.log({ page })}
          />
        </Table>
    </>
  );
}