import { baseUrl } from "../sevices/API_URL";

const useHashId = () => {
    const upload = async (file) => {
        let formData = new FormData();
        formData.append("file", file)
        const response = await fetch(`${baseUrl}/api/file-storage/upload`, {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
                //"Content-type":"application/json"
            },
        })
        const { data } = await response.json();
        return data.id;
    }
    return upload;
}

export default useHashId;