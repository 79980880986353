import React from "react";
import "../../styles/deleteModal.css"

export default function DeleteMoadal({onDelete, setShowModal}) {
  return (
    <>
      <div className="overlay"></div>
      <div className='delete_modal_warpper'>
          <h2 className='delete_modal_title'>O'chirmoqchimisiz?</h2>
          <div className='delete_modal_buttons_wrapper'>
              <button onClick={() => setShowModal(false)}  className='delete_modal_button_no' >Yo'q</button>
              <button onClick={onDelete}  className='delete_modal_button_yes' >Ha</button>
          </div>
      </div>
    </>
  );
}
