import { useCallback } from 'react'


export const useHttp = () => {
    const request = useCallback(async (url, method = "GET", body = null, headers = { "Content-type": "application/json" }) => {
        try {
            const response = await fetch(url, { method, body, headers })
            const st = response.status
            const st2 = response.ok
            if (!response.ok) {
                return {
                    status:st,
                    ok:st2
                }
            }
            const data = await response.json()
            return {...data, status:st};
        } catch (e) {
            console.log(e)
        }
    }, []);
    return { request };
}