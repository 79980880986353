import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allAdmins:[],
    allModerators: [],
}

export const superAdminSlice = createSlice({
    name: "superAdmin",
    initialState,
    reducers: {
        allAdminsList: (state, { payload }) => {
            state.allAdmins = payload
        },
        deletedAdmin: (state, { payload }) => {
            state.allAdmins = state.allAdmins.filter(element => element.id !== payload)
        },
        allModerator: (state, { payload }) => {
            state.allModerators = payload
        },
        deletedModerator: (state, { payload }) => {
            state.allModerators = state.allModerators.filter(element => element.id !== payload)
        },
    }
})

const { actions, reducer } = superAdminSlice;

export default reducer;
export const { deletedAdmin, allAdminsList,allModerator, deletedModerator} = actions;