import React, { useState, useEffect, useMemo } from "react";
import { Modal, Input, Button, Text, Dropdown } from "@nextui-org/react";
import { UserName } from "../../superAdmin/superAdmin_AddAdmin/UserName";
import { useHttp } from "../../../sevices/useHttp";
import { API_URL_moderator_get_pch_list } from "../../../sevices/API_URL";

export default function AddAdmin_PChModal(props) {

    const { request } = useHttp()
    const { visible, closeHandler, onSubmit } = props;
    const [full_name, setFull_name] = useState("");
    const [job_name, setJob_name] = useState("");
    const [phone_number, setPhone_number] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [selected, setSelected] = useState([]);
    const [allStation, setAllStation] = useState([]);



    const selectedValue = useMemo(
        () => Array.from(selected).join(", "),
        [selected]
    );

    const select = selectedValue;
    //console.log(select);

    const handlerSubmit = () => {
        const obj = {
            full_name,
            job_name,
            phone_number,
            username,
            password,
            org_id: select,
        }
        onSubmit(obj)
        closeHandler()
    };

    // Get PCh List ==============================================================================

    useEffect(() => {
        request(API_URL_moderator_get_pch_list, "GET", null, {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json"
        })
            .then(response => {
                setAllStation(response.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    return (
        <div>
            <Modal
                closeButton
                blur
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
            >
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        ShCh Admin Qo'sish
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        value={full_name}
                        onChange={e => setFull_name(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="F.I.SH"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={job_name}
                        onChange={e => setJob_name(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Kasbi"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={phone_number}
                        onChange={e => setPhone_number(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Telefon Raqami"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Username"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Password"
                        contentLeft={<UserName fill="currentColor" />}
                    />

                    <Dropdown>
                        <Dropdown.Button flat color="secondary" css={{ tt: "capitalize" }}>
                            {selectedValue}
                        </Dropdown.Button>
                        <Dropdown.Menu
                            aria-label="Single selection actions"
                            color="secondary"
                            disallowEmptySelection
                            selectionMode="single"
                            selectedKeys={selected}
                            onSelectionChange={setSelected}
                        >
                            {allStation?.length ? allStation.map((item) => (
                                <Dropdown.Item key={item.id}>{item.name}</Dropdown.Item>
                            )) : null}
                        </Dropdown.Menu>
                    </Dropdown>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat color="error" onClick={closeHandler}>
                        Bekor qilish
                    </Button>
                    <Button auto onClick={handlerSubmit}>
                        Saqlash
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}