import React,{useState, useEffect} from "react";
import "../../styles/editModal.css";
import { useHttp } from "../../sevices/useHttp";
import { baseUrl } from "../../sevices/API_URL";

export default function EditModalOrg({id, setShowEditModal, showEditModal, getAllOrganization}) {

  const {request} = useHttp()
  const [name, setName] = useState("")
  const [oneOrg, setOneOrg] = useState({})

  // EDIT Admin========================================================================
  const onEdit = () => {

    const editedOrg = {id, name}
    request(`${baseUrl}/api/organization/edit`, "POST", JSON.stringify(editedOrg),{
        "Authorization" : `Bearer ${localStorage.getItem("token")}`,
        "Content-type" : "application/json"
    }).then(response => {
        getAllOrganization()
    }).catch((err) => {
        console.log(err);
    })
    setShowEditModal(!showEditModal)
};

// Get one Admin=========================================================================
useEffect(() => {
  request(`${baseUrl}/api/organization/view/${id}`, "GET", null, {
    "Authorization" : `Bearer ${localStorage.getItem("token")}`,
    "Content-type" : "application/json"
})
.then(response => {
  setOneOrg(response?.data)
})
.catch((err) => {
    console.log(err);
})
}, [request, id]) 

useEffect(() => {
  if(oneOrg){
    setName(oneOrg?.name)
  }
}, [oneOrg]) 



  return (
    <div className="edit_modal_wrapper">
      <div class="modal-container"></div>
      <div className="edit_input_group">
        <h3 className="edit_modal_title">Tahrirlash!</h3>
        <div className="edit_input_group_wrapper">
          <div className="inputbox">
            <input 
            type="text" 
            required="required"
            value={name}
            onChange={(e) => setName(e.target.value)}
            />
            <span>Nomi</span>
          </div>
        </div>
        <div className="edit_modal_button_wrapper">
          <div class="center">
            <div class="outer button">
              <button onClick={() => setShowEditModal(!showEditModal)}>Yopish</button>
              <span></span>
              <span></span>
            </div>
            <div class="outer button">
              <button onClick={onEdit}>Saqlash</button>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
