import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allStations:[],
    allChildOrg:[],
    allChildOrgPch:[],
    allAdminsShCh:[],
    allAdminsPCh:[],
}

export const moderatorSlice = createSlice({
    name: "moderators",
    initialState,
    reducers: {
        allStationList: (state, { payload }) => {
            state.allStations = payload
        },
        deletedStation: (state, { payload }) => {
            state.allStations = state.allStations.filter(element => element.id !== payload)
        },
        allChildOrgList: (state, { payload }) => {
            state.allChildOrg = payload
        },
        deletedChildOrg: (state, { payload }) => {
            state.allChildOrg = state.allChildOrg.filter(element => element.id !== payload)
        },
        allChildOrgPchList: (state, { payload }) => {
            state.allChildOrgPch = payload
        },
        deletedChildOrgPch: (state, { payload }) => {
            state.allChildOrgPch = state.allChildOrg.filter(element => element.id !== payload)
        },
        allAdminsForShCh: (state, { payload }) => {
            state.allAdminsShCh = payload
        },
        deleteAdminsForShCh: (state, { payload }) => {
            state.allAdminsShCh = state.allAdminsShCh.filter(element => element.id !== payload)
        },
        allAdminsForPCH: (state, { payload }) => {
            state.allAdminsPCh = payload
        },
        deleteAdminsForPCH: (state, { payload }) => {
            state.allAdminsPCh = state.allAdminsPCh.filter(element => element.id !== payload)
        },
    }
})

const { actions, reducer } = moderatorSlice;

export default reducer;
export const { deletedStation, allStationList, deletedChildOrg, allChildOrgList,deletedChildOrgPch, allChildOrgPchList, deleteAdminsForShCh, allAdminsForShCh, deleteAdminsForPCH, allAdminsForPCH} = actions;