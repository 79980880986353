import React, { useState, useEffect, } from "react";
import { Modal, Input,  Button, Text } from "@nextui-org/react";
import { UserName } from "../../components/superAdmin/superAdmin_AddAdmin/UserName";
import { useHttp } from "../../sevices/useHttp";
import { baseUrl } from "../../sevices/API_URL";

export default function Add_Employees_Shch_modal(props) {

    const { visible, closeHandler, onSubmit } = props;
    const { request } = useHttp()
    const [full_name, setFull_name] = useState("");
    const [job_name, setJob_name] = useState("");
    const [phone_number, setPhone_number] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [select1, setSelect1] = useState([]);
    const [allStation, setAllStation] = useState([]);

    const handlerSubmit = () => {
        const obj = {
            full_name,
            job_name,
            phone_number,
            username,
            password,
            station_id: select1
        }
        onSubmit(obj)
        closeHandler()
    };

    // Get PCh staion List ==============================================================================


    useEffect(() => {
        request(`${baseUrl}/api/employee/station/list`, "GET", null, {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json"
        })
            .then(response => {
                setAllStation(response.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])


    return (
        <div>
            <Modal
                closeButton
                blur
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
            >
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        SHCH ishchi qo'shish
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        value={full_name}
                        onChange={e => setFull_name(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="F.I.SH."
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={job_name}
                        onChange={e => setJob_name(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Lavozimi"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={phone_number}
                        onChange={e => setPhone_number(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Telefon raqami"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Login"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Parol"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <div className="input-group">
                        <select value={select1} onChange={e => setSelect1(e.target.value)} className="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
                            <option selected>Stansiyani tanlang</option>
                            {allStation?.length ? allStation.map((item) => (
                                <option value={item.id}>{item.name}</option>
                            )) : null}
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat color="error" onClick={closeHandler}>
                        Bekor qilish
                    </Button>
                    <Button auto onClick={handlerSubmit}>
                        Saqlash
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}