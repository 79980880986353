import { useEffect, useState } from "react";
import {Table,Row,Col,Tooltip,Text,Grid,Spacer,Container, Input} from "@nextui-org/react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "../../components/superAdmin/superAdminAdmins/IconButton";
import { EditIcon } from "../../components/superAdmin/superAdminAdmins/EditIcon";
import { DeleteIcon } from "../../components/superAdmin/superAdminAdmins/DeleteIcon";
import { useHttp } from "../../sevices/useHttp";
import Paginationnnnn from "../pagination/Paginationnnnn";
import {API_URL_employee_pch_get_all_status_izostik,API_URL_employee_pch_get_all_statistics,API_URL_employee_pch_get_all_izostik,} from "../../sevices/API_URL";
import momentTimezone from "moment-timezone";
import {allIzostikForStation,allIzostikByStatus,} from "../../redux/employee_pch_slice";
import { useSearchParams, useNavigate } from "react-router-dom";
import EditIzostikModal from "./EditIzostikModal";
import { baseUrl } from "../../sevices/API_URL";
import LoadingPage from "../../loading/LoadingPage";
import "../statisticsPages/statistics.css"

export default function Employee_Pch_Profile() {
  const { request } = useHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [searchParams, setSearrchParams] = useSearchParams();
  let initialValue = searchParams.get("page") ? searchParams.get("page") : 1;
  const [lenth, setLength] = useState("");
  const [stikId, setStikId] = useState("");
  const [status, setStatus] = useState("")
  const [searchIzo, setSearchIzo] = useState("")

  const { allIzostik, izostikByStatus } = useSelector((state) => state.employeePch);
  

  const [visible, setVisible] = useState(false);
  const handler = (id) => {
    setVisible(true)
    setStikId(id)
}
  const closeHandler = () => {
      setVisible(false);
  };

  // Get Izostik For Pch=====================================================================

  const gettAllIzostik = () => {
    request(
      `${baseUrl}/api/employee-stick-pch/find/search?search=${searchIzo}&page=${initialValue}&limit=8`,
      "GET",
      null,
      {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json",
      }
    )
      .then((response) => {
        dispatch(allIzostikForStation(response.items));
        setLength(response?.meta);
        setStatus(response?.status)
      })
      .catch((err) => {
        console.log(err);
      });
}
useEffect(() => {
    gettAllIzostik()
}, [initialValue, searchIzo])


  useEffect(() => {
    request(API_URL_employee_pch_get_all_statistics, "GET", null, {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-type": "application/json",
    })
      .then((response) => {
        dispatch(allIzostikByStatus(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [request]);

    // Post Edited Izostik For Pch======================================================================
    const onSubmit = (obj) => {
      request(`${baseUrl}/api/employee-stick-pch/edit/stick/pch`, "POST", JSON.stringify(obj), {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json"
      }).then(response => {
          gettAllIzostik()
          console.log(response)
      }).catch((err) => {
          console.log(err);
      })
  };
  

  const columns = [
    { name: "STANSIYA NOMI", uid: "__station__" },
    { name: "NOMI", uid: "name" },
    { name: "RELS TURI", uid: "rail_type" },
    { name: "JOYLASHGAN JOYI", uid: "location" },
    { name: "YO'LNING TURI", uid: "road" },
    { name: "KO'RIKDAN O'TKAZILGAN SANA", uid: "start_date" },
    { name: "KEYINGI KO'RIKDAN O'TKAZISH SANASI", uid: "finish_date" },
    { name: "STATUS", uid: "status" },
    { name: "ACTIONS", uid: "actions" },
  ];
  const users = allIzostik;

  const renderCell = (user, columnKey) => {
    const cellValue = user[columnKey];
    switch (columnKey) {
      case "__station__":
        return (
          <Col>
            <Row>
              <Text b size={14}>
                {cellValue.name}
              </Text>
            </Row>
          </Col>
        );
      case "name":
        return (
          <Col>
            <Row>
              <Text b size={14}>
                {cellValue}
              </Text>
            </Row>
          </Col>
        );
      case "rail_type":
        return (
          <Col>
            <Row>
              <Text b size={14}>
                {cellValue}
              </Text>
            </Row>
          </Col>
        );
      case "location":
        return (
          <Col>
            <Row>
              <Text b size={14}>
                {cellValue}
              </Text>
            </Row>
          </Col>
        );
      case "road":
        return (
          <Col>
            <Row>
              <Text b size={14}>
                {cellValue === "MAIN" ? "ASOSIY YO'L" : "QO'SHIMCHA YO'L"}
              </Text>
            </Row>
          </Col>
        );
      case "start_date":
        return (
          <Col>
            <Row>
              <Text b size={14}>
                {momentTimezone(cellValue)
                  .tz("Asia/Tashkent")
                  .format("DD.MM.YYYY")}
              </Text>
            </Row>
          </Col>
        );
      case "finish_date":
        return (
          <Col>
            <Row>
              <Text b size={14}>
                {momentTimezone(cellValue)
                  .tz("Asia/Tashkent")
                  .format("DD.MM.YYYY")}
              </Text>
            </Row>
          </Col>
        );
        case "status":
          return (
            <Col>
              <Row>
                <Text b size={14}>
                {cellValue === "EDIT_PCH" ? "SHCH ishini tugatmadi" : cellValue === "EDIT_SHCH" ? "PCH ishini tugatmadi" : null}
                </Text>
              </Row>
            </Col>
          );
      case "actions":
        return (
          <Row justify="center" align="center">
            <Col css={{ d: "flex" }}>
                <IconButton onClick={() => handler(user.id)}>
                  <EditIcon size={20} fill="#979797" />
                </IconButton>
            </Col>
            <Col css={{ d: "flex" }}>
              <Tooltip
                content="Delete user"
                color="error"
                onClick={() => console.log("Delete user", user.id)}
              >
                <IconButton>
                  <DeleteIcon size={20} fill="#FF0080" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        );
      default:
        return cellValue;
    }
  };
  if(status === 200){
    return (
      <Container lg>
        <EditIzostikModal
          visible={visible}
          closeHandler={closeHandler}
          stikId={stikId}
          onSubmit={onSubmit}
        />
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div onClick={() => navigate("/employeePchProfile/whiteStik", { state: "WHITE"})} className="statistics_card_wrapper">
            <div className="statistics_card">
              <div className="statistics_count">
                <h3 style={{color:"#3366FF"}} className="Statistics_count_number">{izostikByStatus?.white}</h3>
                <h4 className="Statistics_count_desc">Muddati o'tmaganlar</h4>
              </div>
              <div className="statistics_icon_wrapper">
                <img src={require('../../assets/Vectoryaqinlawmagan.png')} alt={"lock images"} className="img-fluid rounded "  />
              </div>
            </div>
            <div style={{backgroundColor:"#3366FF"}} className="statistics_card_footer">
              <p className="statistics_card_footer_text">Stansiya miqyosida</p>
              <div className="statistics_footer_icon_wrapper">
                <i className="fa-solid fa-landmark "></i>
              </div>
            </div>
          </div>
          <div onClick={() => navigate("/employeePchProfile/whiteStik", { state: "YELLOW"})} className="statistics_card_wrapper">
            <div className="statistics_card">
              <div className="statistics_count">
                <h3 style={{color:"rgb(244, 161, 0)"}} className="Statistics_count_number">{izostikByStatus?.yellow}</h3>
                <h4 className="Statistics_count_desc">Muddati yaqinlashgan</h4>
              </div>
              <div className="statistics_icon_wrapper">
                <img src={require('../../assets/Groupyaqinlawganlar.png')} alt={"lock images"} className="img-fluid rounded" />
              </div>
            </div>
            <div  style={{backgroundColor:"rgb(244, 161, 0)"}} className="statistics_card_footer">
              <p className="statistics_card_footer_text">Stansiya miqyosida</p>
              <div className="statistics_footer_icon_wrapper">
                <i className="fa-solid fa-landmark"></i>
              </div>
            </div>
          </div>
          
          <div onClick={() => navigate("/employeePchProfile/whiteStik", { state: "RED"})} className="statistics_card_wrapper">
            <div className="statistics_card">
              <div className="statistics_count">
                <h3 style={{color:"rgb(255, 65, 58)"}} className="Statistics_count_number">{izostikByStatus?.red}</h3>
                <h4 className="Statistics_count_desc">Muddati o'tganlar</h4>
              </div>
              <div className="statistics_icon_wrapper">
                <img src={require('../../assets/Groupmuddatiotganlar.png')} alt={"lock images"} className="img-fluid rounded" />
              </div>
            </div>
            <div style={{backgroundColor:"rgb(255, 65, 58)"}} className="statistics_card_footer">
              <p className="statistics_card_footer_text">Stansiya miqyosida</p>
              <div className="statistics_footer_icon_wrapper">
                <i  className="fa-solid fa-landmark "></i>
              </div>
            </div>
          </div>

          <div onClick={() => navigate("/employeePchProfile/whiteStik", { state: "EDIT_PCH"})} className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3 style={{color:"#D6009A"}} className="Statistics_count_number">{izostikByStatus.edit_pch + izostikByStatus.edit_shch }</h3>
              <h4 className="Statistics_count_desc">Ishlar olib borilayotgan</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <img src={require('../../assets/yakunlanmaganiwlar.png')} alt={"lock images"} className="img-fluid rounded " />
            </div>
          </div>
          <div style={{backgroundColor:"#D6009A"}} className="statistics_card_footer">
            <p className="statistics_card_footer_text">Stansiya miqyosida</p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>
        </div>
        <Grid.Container>
        </Grid.Container>
        <Spacer y={2} />
        <Grid>
        <Input 
          underlined 
          labelPlaceholder="Qidirish" 
          color="success" 
          value={searchIzo}
          onChange={(e) => setSearchIzo(e.target.value)}
        />
      </Grid>
        <Spacer y={1} />
        <Row>
          <Col>
            <Table
              aria-label="Example table with custom cells"
              css={{
                height: "auto",
                minWidth: "100%",
              }}
              selectionMode="none"
            >
              <Table.Header columns={columns}>
                {(column) => (
                  <Table.Column
                    key={column.uid}
                    hideHeader={column.uid === "actions"}
                    align={column.uid === "actions" ? "center" : "start"}
                  >
                    {column.name}
                  </Table.Column>
                )}
              </Table.Header>
              <Table.Body items={users}>
                {(item) => (
                  <Table.Row
                    css={
                      item.status === "WHITE"
                        ? { background: "$white" }
                        : item.status === "RED"
                        ? { background: "#F75F94" }
                        : item.status === "EDIT_SHCH" || item.status === "EDIT_PCH"
                        ? { background: "#09AACD" }
                        : { background: "#F8C572" }
                    }
                  >
                    {(columnKey) => (
                      <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                    )}
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Col>
        </Row>
        <Spacer y={1} />
        <Row>
          <Col>
            <Paginationnnnn
              setSearrchParams={setSearrchParams}
              initialValue={initialValue}
              totalPage={lenth.totalPages}
            />
          </Col>
        </Row>
      </Container>
    );
  }else{
    return(
      <LoadingPage/>
    )
  }
}
