import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Text, Textarea } from "@nextui-org/react";
import { UserName } from "../superAdmin/superAdmin_AddAdmin/UserName";
import { useHttp } from "../../sevices/useHttp";
import useHashId from "../../hooks/useHashId";
import momentTimezone from "moment-timezone"
import { baseUrl } from "../../sevices/API_URL";

export default function EditIzostikModalSHCH(props) {
  const { request } = useHttp()
    const upload = useHashId()
    const { visible, closeHandler, onSubmit, stikId } = props;
    const [location, setLocation] = useState("");
    const [rail_type, setRail_type] = useState("");
    const [road, setRoad] = useState("");
    const [xabarFayli, setxabarFayli] = useState("");
    const [infoAboutStik, setInfoAboutStik] = useState({});
    const [description, setDescription] = useState("");

  const handlerSubmit = async () => {
    const file_id = await upload(xabarFayli);

    const obj = {
      id : stikId,
      description,
      file_id,
    };
    onSubmit(obj);
    closeHandler();
  };
  // Get Station List ==============================================================================

  useEffect(() => {
    request(`${baseUrl}/api/employee-stick-pch/find/by/${stikId}`, "GET", null, {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-type": "application/json",
    })
      .then((response) => {
        setInfoAboutStik(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
      
  }, [stikId, request]);


  useEffect(() => {
    if(infoAboutStik){
      setLocation(infoAboutStik?.location)
      setDescription(infoAboutStik?.description)
      setRail_type(infoAboutStik?.rail_type)
      setRoad(infoAboutStik?.road)
    }
  }, [infoAboutStik])


  
  

  return (
    <Modal
      closeButton
      blur
      aria-labelledby="modal-title"
      open={visible}
      onClose={closeHandler}
    >
      <Modal.Header>
        <Text id="modal-title" size={18}>
          Tahrirlash
        </Text>
      </Modal.Header>
          <Modal.Body>
            <Input
                placeholder={infoAboutStik?.name}
                status="error"
                disabled
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                contentLeft={<UserName fill="currentColor" />}
            />
            <Input
                placeholder={infoAboutStik?.location}
                disabled
                clearable
                bordered
                fullWidth
                color="error"
                size="lg"
                contentLeft={<UserName fill="currentColor" />}
            />
            <Textarea
              value={description} 
              onChange={(e) => setDescription(e.target.value)} 
              placeholder="Izoh"
              bordered
              color="primary" 
              />

            <div className="input-group">
                <select disabled value={rail_type} onChange={e => setRail_type(e.target.value)} className="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
                    <option selected>Rels turini tanlang</option>
                    <option vaue={"R43"}>R43</option>
                    <option value={"R50"}>R50</option>
                    <option value={"R65"}>R65</option>
                    <option value={"R75"}>R75</option>

                </select>
            </div>
            <div className="input-group">
                <select disabled value={road} onChange={e => setRoad(e.target.value)} className="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
                    <option selected>Yo'l turini tanlang</option>
                    <option value={"MAIN"}>Asosiy yo'l</option>
                    <option value={"ADDITIONAL"}>Qo'shimcha yo'l</option>
                </select>
            </div>
            <Input
                placeholder={momentTimezone(infoAboutStik?.start_date).tz('Asia/Tashkent').format('DD.MM.YYYY')}
                status="error"
                disabled
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                contentLeft={<UserName fill="currentColor" />}
            />
            <Input
                placeholder={momentTimezone(infoAboutStik?.finish_date).tz('Asia/Tashkent').format('DD.MM.YYYY')}
                status="error"
                disabled
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                contentLeft={<UserName fill="currentColor" />}
            />

            <div className="mb-3">
                <label for="formFile" className="form-label">Fayl biriktirish</label>
                <input onChange={e => setxabarFayli(e.target.files[0])} className="form-control" type="file" id="formFile" />
            </div>

        </Modal.Body>
      <Modal.Footer>
        <Button auto flat color="error" onClick={closeHandler}>
          Bekor qilish
        </Button>
        <Button auto onClick={handlerSubmit}>
          Saqlash
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
