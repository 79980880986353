import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export default function ModeratorEmpStatistics ({statistics}) {

    const shchc = statistics?.arrShch
    const pchc = statistics?.arrPch
    

    
    const dataStatisPch = pchc?.map((item, index, arr) => {
        return(
            {name:item.name, Xodimlar:arr[index].employeeCount}
        )
    })
    
    const dataStatisShch = shchc?.map((item, index, arr) => {
        return(
            {name:item.name, Xodimlar:arr[index].employeeCount}
        )
    })

    const data = [...dataStatisPch, ...dataStatisShch]

    return (
    <>
        <h3 className='text-center my-3'>Brcha SHCH va PCH ishchilari haqida ma'lumot</h3>
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            barSize={20}
            >
            <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="Xodimlar" fill="#8884d8" background={{ fill: '#eee' }} />
            </BarChart>
        </ResponsiveContainer>
    </>
    );
}
