import { configureStore} from "@reduxjs/toolkit"
import logins from "./redux/loginSlice"
import superAdmin from "./redux/superAdminSlice"
import moderators from "./redux/moderatorSlice"
import pch_admins from "./redux/adminPchSlice"
import employeePch from "./redux/employee_pch_slice";
import shch_admins from "./redux/adminShchSlice"
import employeeshch from "./redux/employee_shch_slice"

export const store = configureStore({
  reducer : {
    logins : logins,
    superAdmin : superAdmin,
    moderators : moderators,
    pch_admins : pch_admins,
    employeePch : employeePch,
    shch_admins : shch_admins,
    employeeshch:employeeshch
  },
})