import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import "../../styles/login.css"
import {  successLogin, errorLogin,requestLogin, successProfile } from "../../redux/loginSlice"
import { useHttp } from '../../sevices/useHttp'
import {API_URL_login} from "../../sevices/API_URL"

export default function Login() {

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const dispatch = useDispatch();
  const {request} = useHttp()


  const signIn = (e) => {

    const userLogin = {username, password}

    if (!username || !password) {
      return;
    }

    dispatch(requestLogin())

    request(API_URL_login, "POST", JSON.stringify(userLogin),
    {
      "Content-type": "application/json",
      "Fcm-token" : `${localStorage.getItem("tokenFirebase")}`,
    })
    .then(response => {
      if(response.ok === true){
        dispatch(successLogin(response?.data?.token));
        dispatch(successProfile(response?.data?.role))
      }else{
        return(
          alert("error")
        )
      }
      console.log(response);
    })
      .catch(error => {
        alert("Error")
        console.log(error.message)
        dispatch(errorLogin());
      })
  }



  return (
    <div className="auth-wrapper">
      <div className="auth-content subscribe">
        <div className="card">
          <div className="row no-gutters" style={{width:"100%", height:"100%", borderRadius:"24px"}}>
            <div className="col-md-4 col-lg-6 d-none d-md-flex d-lg-flex theme-bg align-items-center justify-content-center flex-lg-column">
              <img src={require('../../assets/Vector-3.ico')} alt={"lock images"} className="img-fluid rounded" style={{filter:"brightness(0) invert(1)"}} />
              <h1 className='text-white'>IZOSTIK</h1>
              <h4 className='text-white'>WEB PLATFORMASI</h4>
            </div>
            <div className="col-md-8 col-lg-6" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
              <div className="card-body text-center">
                <div className="row justify-content-center">
                  <div className="col-sm-10">
                    <h3 className="mb-4">Tizimga kirish</h3>
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" placeholder="Email" value={username} onChange={e => setusername(e.target.value)} />
                    </div>
                    <div className="input-group mb-4">
                      <input type="password" className="form-control" placeholder="password" value={password} onChange={e => setpassword(e.target.value)} />
                    </div>
                    <div className="form-group text-left">
                    </div>
                    <button onClick={signIn} className="btn btn-primary shadow-2 mb-4">Login</button>
                    <p className="mb-2 text-muted">Forgot password?</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
