import React from "react";
import { Container } from "@nextui-org/react";
import "../../statisticsPages/statistics.css";
export default function StatisticsforSHCHadmin({ statistics }) {
  return (
    <>
      <Container lg>
        <h3 className="text-center mt-4">
          SHCH ga tegishli izostiklar va ishchilar haqida ma'lumot
        </h3>
        <div className="statistics_wrapper">
        <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3 style={{color:"#3366FF"}} className="Statistics_count_number">{statistics?.white}</h3>
              <h4 className="Statistics_count_desc">Muddati o'tmaganlar</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <img src={require('../../../assets/Vectoryaqinlawmagan.png')} alt={"lock images"} className="img-fluid rounded "  />
            </div>
          </div>
          <div style={{backgroundColor:"#3366FF"}} className="statistics_card_footer">
            <p className="statistics_card_footer_text">SHCH miqyosida</p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>

        <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3 style={{color:"rgb(244, 161, 0)"}} className="Statistics_count_number">{statistics?.yellow}</h3>
              <h4 className="Statistics_count_desc">Muddati yaqinlashgan</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <img src={require('../../../assets/Groupyaqinlawganlar.png')} alt={"lock images"} className="img-fluid rounded" />
            </div>
          </div>
          <div  style={{backgroundColor:"rgb(244, 161, 0)"}} className="statistics_card_footer">
            <p className="statistics_card_footer_text">SHCH miqyosida</p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark"></i>
            </div>
          </div>
        </div>

      <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3 style={{color:"rgb(255, 65, 58)"}} className="Statistics_count_number">{statistics?.red}</h3>
              <h4 className="Statistics_count_desc">Muddati o'tganlar</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <img src={require('../../../assets/Groupmuddatiotganlar.png')} alt={"lock images"} className="img-fluid rounded" />
            </div>
          </div>
          <div style={{backgroundColor:"rgb(255, 65, 58)"}} className="statistics_card_footer">
            <p className="statistics_card_footer_text">SHCH miqyosida</p>
            <div className="statistics_footer_icon_wrapper">
              <i  className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>

          <div className="statistics_card_wrapper">
            <div className="statistics_card">
              <div className="statistics_count">
                <h3
                  style={{ color: "rgb(51, 102, 255)" }}
                  className="Statistics_count_number"
                >
                  {statistics?.employeeCount}
                </h3>
                <h4 className="Statistics_count_desc">Ishchilar soni</h4>
              </div>
              <div className="statistics_icon_wrapper">
                <i
                  style={{ color: "#C2D1FF" }}
                  className="fa-solid fa-landmark fa-2x"
                ></i>
              </div>
            </div>
            <div
              style={{ backgroundColor: "rgb(51, 102, 255)" }}
              className="statistics_card_footer"
            >
              <p className="statistics_card_footer_text">SHCH miqyosida</p>
              <div className="statistics_footer_icon_wrapper">
                <i className="fa-solid fa-landmark "></i>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
