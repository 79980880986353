import React,{useEffect, useState} from 'react';
import { useHttp } from '../../sevices/useHttp';
import { baseUrl } from '../../sevices/API_URL';
import LoadingPage from '../../loading/LoadingPage';
import StatisticsAdminPch from '../statisticsPages/adminPch/StatisticsAdminPch';


function Admin_PCH_Profile() {

    const {request} = useHttp()

    const [status, setStatus]  = useState("")
    const [statisticsPchadmin, setStatisticsPchadmin] = useState({})

    useEffect(() => {
    request(`${baseUrl}/api/statistic/find/all`, "GET", null,{
        "Authorization" : `Bearer ${localStorage.getItem("token")}`,
        "Content-type" : "application/json"
    }).then(response => {
        setStatus(response?.status)
        setStatisticsPchadmin(response?.data)
    }).catch((err) => {
        console.log(err);
    })
}, [request])

    return (
        <>
            {status ? <StatisticsAdminPch statistics={statisticsPchadmin}/> : <LoadingPage/>}
        </>
    );
}

export default Admin_PCH_Profile;