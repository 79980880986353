import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allIzostik:[],
    izostikByStatus:{},
}

export const employeePchSlice = createSlice({
    name: "employeePch",
    initialState,
    reducers: {
        allIzostikForStation: (state, { payload }) => {
            state.allIzostik = payload
        },
        allIzostikByStatus: (state, { payload }) => {
            state.izostikByStatus = payload
        },
    }
})

const { actions, reducer } = employeePchSlice;

export default reducer;
export const {allIzostikForStation, allIzostikByStatus} = actions;