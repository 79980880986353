import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Text } from "@nextui-org/react";
import { UserName } from "../../components/superAdmin/superAdmin_AddAdmin/UserName";
import { useHttp } from "../../sevices/useHttp"
import { API_URL_pchAdmin_get_station } from "../../sevices/API_URL";
import momentTimezone from "moment-timezone"
import useHashId from "../../hooks/useHashId";




export default function Add_Izostik_Modal(props) {

    const { request } = useHttp()
    const upload = useHashId()
    const { visible, closeHandler, onSubmit } = props;
    const [name, setName] = useState("");
    const [station_id, setStation_id] = useState([]);
    const [location, setLocation] = useState("");
    const [select, setSelect] = useState("");
    const [rail_type, setRail_type] = useState("");
    const [start_date, setStart_date] = useState("");
    const [road, setRoad] = useState("");
    const [xabarFayli, setxabarFayli] = useState("");
    const [desc, setDesc] = useState("");

    const handlerSubmit = async () => {

        const file_id = await upload(xabarFayli);

        const obj = {
            name,
            station_id: select,
            location,
            rail_type,
            start_date: momentTimezone(new Date(start_date)).format('YYYY.MM.DD'),
            road,
            file_id,
            desc
        }
            onSubmit(obj) 
            closeHandler() 
    }
    // Get ShCh List ==============================================================================

    useEffect(() => {
        request(API_URL_pchAdmin_get_station, "GET", null, {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json"
        })
            .then(response => {
                setStation_id(response.data)
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    return (
        <div>
            <Modal
                closeButton
                blur
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
            >
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        Izostik yaratish
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Nomi"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={location}
                        onChange={e => setLocation(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Joylashuvi"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={desc}
                        onChange={e => setDesc(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Izoh"
                        contentLeft={<UserName fill="currentColor" />}
                    />

                    <div className="input-group">
                        <select value={rail_type} onChange={e => setRail_type(e.target.value)} className="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
                            <option selected>Rels turini tanlang</option>
                            <option value={"R43"}>R43</option>
                            <option value={"R50"}>R50</option>
                            <option value={"R65"}>R65</option>
                            <option value={"R75"}>R75</option>

                        </select>
                    </div>
                    <div className="input-group">
                        <select value={road} onChange={e => setRoad(e.target.value)} className="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
                            <option selected>Yo'l turini tanlang</option>
                            <option value={"MAIN"}>Asosiy yo'l</option>
                            <option value={"ADDITIONAL"}>Qo'shimcha yo'l</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <input value={start_date} onChange={e => setStart_date(e.target.value)} className="form-control" type={"date"} />
                    </div>

                    <div value={select} onChange={e => setSelect(e.target.value)} className="input-group">
                        <select className="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
                            <option selected>Stansiyani tanlang</option>
                            {station_id?.length ? station_id.map((item) => (
                                <option value={item.id}>{item.name}</option>
                            )) : null}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label for="formFile" className="form-label">Fayl biriktirish</label>
                        <input onChange={e => setxabarFayli(e.target.files[0])} className="form-control" type="file" id="formFile" />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat color="error" onClick={closeHandler}>
                        Bekor qilish
                    </Button>
                    <Button auto onClick={handlerSubmit}>
                        Saqlash
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}