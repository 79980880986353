import React, {useEffect, useState} from 'react';
import SuperAdminStatistics from '../statisticsPages/superAdmin/SuperAdminStatistics';
import { useHttp } from '../../sevices/useHttp';
import { baseUrl } from '../../sevices/API_URL';
import LoadingPage from '../../loading/LoadingPage';

function SuperAdminProfile() {

    const {request} = useHttp()
    const [statistics, setStatistics] = useState({})
    const [status, setStatus] = useState("")

    useEffect(() => {
        request(`${baseUrl}/api/statistic/find/all`, "GET", null,{
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json",
            "Fcm-token" : `${localStorage.getItem("tokenFirebase")}`,
        }).then(response => {
            setStatistics(response?.data)
            setStatus(response?.status)
        }).catch((err) => {
            console.log(err);
        })
    }, [request])


    useEffect(() => {
        request(`${baseUrl}/api/stick/find/all?`, "GET", null,{
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json",
            "Fcm-token" : `${localStorage.getItem("tokenFirebase")}`,
        }).then(response => {
        }).catch((err) => {
            console.log(err);
        })
    }, [request])



    return (
        <div>
            {status ? <SuperAdminStatistics statistics={statistics}/> : <LoadingPage/>}
        </div>
    );
}

export default SuperAdminProfile;