
import { useEffect } from "react";
import { Table, Row, Col, Tooltip, User, Text, Button, Spacer, Container, Modal } from "@nextui-org/react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "../../components/superAdmin/superAdminAdmins/IconButton";
import { EyeIcon } from "../../components/superAdmin/superAdminAdmins/EyeIcon";
import { EditIcon } from "../../components/superAdmin/superAdminAdmins/EditIcon";
import { DeleteIcon } from "../../components/superAdmin/superAdminAdmins/DeleteIcon";
import { useState } from "react";
import { useHttp } from "../../sevices/useHttp";
import Add_Employees_Shch_modal from "./Add_Employees_Shch_modal";
import Paginationnnnn from "../pagination/Paginationnnnn";
import {allEmployeesShchList, deletedEmployeesShch} from "../../redux/adminShchSlice"
import momentTimezone from "moment-timezone"
import { baseUrl } from "../../sevices/API_URL";
import { useSearchParams } from "react-router-dom";
import LoadingPage from "../../loading/LoadingPage";
import DeleteMoadal from "../modals/DeleteMoadal";
import EditModalEmpShch from "../modals/EditModalEmpShch";

export default function Epmloyees_SHCH() {

    const {request} = useHttp()
    const dispatch = useDispatch()
    const {allEmployeesShch} = useSelector(state => state.shch_admins)
    const [visible, setVisible] = useState(false);
    const [searchParams, setSearrchParams] = useSearchParams()
    let   initialValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const [lenth, setLength] = useState("")
    const [status, setStatus] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [getId, setgGetId] = useState("")
    


    const handler = () => setVisible(true)
    const closeHandler = () => {
        setVisible(false);
    };

    const showDeleteModal = (id) => {
        setShowModal(!showModal)
        setgGetId(id)
    }

    const showEditModalFunc = (id) => {
        setShowEditModal(!showEditModal)
        setgGetId(id)
    }

    // Post Employees For SHch Admin======================================================================
    const onSubmit = (obj) => {
        request(`${baseUrl}/api/employee/create`, "POST", JSON.stringify(obj),{
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        }).then(response => {
            gettAllEmployeesPch()
            console.log(response);
        }).catch((err) => {
            console.log(err);
        })
    };

    // Get Employees For Shch Admin=====================================================================

    const gettAllEmployeesPch = () => {
        request(`${baseUrl}/api/employee/find/all?page=${initialValue}&limit=10`, "GET", null, { 
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        })
        .then(response => {
            dispatch(allEmployeesShchList(response.items))
            setLength(response.meta)
            setStatus(response?.status)
        })
        .catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        gettAllEmployeesPch()
    }, [initialValue]) 

      // Delete Admin========================================================================
    const onDelete = (id) => {
        request(`${baseUrl}/api/employee/delete/${id}`, "DELETE", null,{
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        }).then(response => {
            dispatch(deletedEmployeesShch(id))
        }).catch((err) => {
            console.log(err);
        })
        setShowModal(!showModal)
    };



    const columns = [
        { name: "F.I.O", uid: "full_name" },
        { name: "USER NAME", uid: "username" },
        { name: "KASBI", uid: "job_name" },
        { name: "TEL NOMER", uid: "phone_number" },
        { name: "YRATILGAAN VAQTI", uid: "created_at" },
        { name: "ACTIONS", uid: "actions" },
    ];
    const users = allEmployeesShch;
    const renderCell = (user, columnKey) => {
        const cellValue = user[columnKey];
        switch (columnKey) {
            case "full_name":
                return (
                    <User squared src={`${baseUrl}/file${user?.__file__?.url_2}`} name={cellValue} css={{ p: 0 }}>
                    </User>
                );
            case "username":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} >
                                {cellValue}
                            </Text>
                        </Row>
                    </Col> 
                );
                case "phone_number":
                return (
                    <Col>
                        <Row>
                            <Text b size={14} >
                                {cellValue}
                            </Text>
                        </Row>
                    </Col> 
                );
                case "created_at":
                    return (
                        <Col>
                            <Row>
                                <Text b size={14} >
                                {momentTimezone(cellValue).tz('Asia/Tashkent').format('DD.MM.YYYY HH:mm')}
                                </Text>
                            </Row>
                        </Col> 
                    );
            case "actions":
                return (
                    <Row justify="center" align="center">
                        <Col css={{ d: "flex" }}>
                            <Tooltip content="Details">
                                <IconButton onClick={() => console.log("View user", user.id)}>
                                    <EyeIcon size={20} fill="#979797" />
                                </IconButton>
                            </Tooltip>
                        </Col>
                        <Col css={{ d: "flex" }}>
                            <Tooltip content="Edit user">
                                <IconButton onClick={() => showEditModalFunc(user?.id)}>
                                    <EditIcon size={20} fill="#979797" />
                                </IconButton>
                            </Tooltip>
                        </Col>
                        <Col css={{ d: "flex" }}>
                            <Tooltip
                                content="Delete user"
                                color="error"
                                onClick={() => showDeleteModal(user?.id)}
                            >
                                <IconButton>
                                    <DeleteIcon size={20} fill="#FF0080" />
                                </IconButton>
                            </Tooltip>
                        </Col>
                    </Row>
                );
            default:
                return cellValue;
        }
    };
    if(status === 200){
        return (
            <Container lg>
                {showEditModal ? <EditModalEmpShch setShowEditModal={setShowEditModal} id={getId} showEditModal={showEditModal} gettAllEmployeesPch={gettAllEmployeesPch}/> :null}
                {showModal ? <DeleteMoadal onDelete={() => onDelete(getId)} setShowModal={setShowModal}/> : null}
                <Add_Employees_Shch_modal
                    visible={visible}
                    closeHandler={closeHandler}
                    onSubmit={onSubmit}
                />
    
                <Spacer y={1} />
                <Row type Display="flex" justify="flex-end">
                    <Button shadow color="primary" auto onClick={handler}>
                        Ishchi yaratish 
                    </Button>
                </Row>
                <Spacer y={1} />
                <Row >
                    <Col>
                        <Table
                            aria-label="Example table with custom cells"
                            css={{
                                height: "auto",
                                minWidth: "100%",
                            }}
                            selectionMode="none"
                        >
                            <Table.Header columns={columns}>
                                {(column) => (
                                    <Table.Column
                                        key={column.uid}
                                        hideHeader={column.uid === "actions"}
                                        align={column.uid === "actions" ? "center" : "start"}
                                    >
                                        {column.name}
                                    </Table.Column>
                                )}
                            </Table.Header>
                            <Table.Body items={users}>
                                {(item) => (
                                    <Table.Row>
                                        {(columnKey) => (
                                            <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                                        )}
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Col>
                </Row>
                <Spacer y={1}/>
                <Row >
                    <Col >
                        <Paginationnnnn setSearrchParams={setSearrchParams} initialValue={initialValue} totalPage={lenth.totalPages}/>
                    </Col>
                </Row>
            </Container>
        );
    }else {
        return(
            <LoadingPage/>
        )
    }
}