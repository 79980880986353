import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allEmployees:[],
    all_Izostik:[],
}

export const admin_pchSlice = createSlice({
    name: "pch_admins",
    initialState,
    reducers: {
        allEmployeesPchList: (state, { payload }) => {
            state.allEmployees = payload
        },
        deletedEmployeesPch: (state, { payload }) => {
            state.allEmployees = state.allEmployees.filter(element => element.id !== payload)
        },
        allIzostik: (state, { payload }) => {
            state.all_Izostik = payload
        },
        deletedIzostik: (state, { payload }) => {
            state.all_Izostik = state.all_Izostik.filter(element => element.id !== payload)
        },
    }
})

const { actions, reducer } = admin_pchSlice;

export default reducer;
export const { allEmployeesPchList, deletedEmployeesPch, deletedIzostik, allIzostik} = actions;