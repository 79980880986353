import React, { useState, useEffect } from 'react';
import { Button, Container, Grid, Spacer, Table } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom"
import { useHttp } from '../../../sevices/useHttp';
import { API_URL_moderator_get_shch_view } from '../../../sevices/API_URL';



function SinglieChildOrgInfo(props) {

    const navigate = useNavigate()
    var { id } = useParams()
    const {request} = useHttp()
    const [singleChildOrg, setSingleChilOrg] = useState([])

    //Get Information of Single Child =============================================================

    const getAllOrganization = () => {
        
    }
    
    useEffect(() => {
        request(`${API_URL_moderator_get_shch_view}/${id}`, "GET", null, {
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        })
        .then(response => {
            setSingleChilOrg(response.data.org_list)
            console.log(response.data.org_list);
        })
        .catch((err) => {
            console.log(err);
        })
    }, [id]) 

    const columns = [
        {
            key: "name",
            label: "STANSIYALAR NOMI",
        },
        {
            key: "role",
            label: "YARATILGAN VAQTI",
        },
    ];

    return (
        <Container lg  >
            <Grid.Container gap={2}>
                <Grid>
                    <Button onClick={() => navigate(-1)} color="gradient" auto>
                        ORQAGA
                    </Button>
                </Grid>
            </Grid.Container>

            <Spacer y={1} />

            <Table
                aria-label="Example table with dynamic content"
                css={{
                    height: "auto",
                    minWidth: "100%",
                }}
            >
                <Table.Header columns={columns}>
                    {(column) => (
                        <Table.Column key={column.key}>{column.label}</Table.Column>
                    )}
                </Table.Header>
                <Table.Body >
                    {singleChildOrg?.length ? singleChildOrg.map((item, index) => (
                        <Table.Row key={item.id}>
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell>{item.created_at}</Table.Cell>
                        </Table.Row>
                    )) : null}
                </Table.Body>
            </Table>
        </Container>
    );
}

export default SinglieChildOrgInfo;