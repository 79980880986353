import React,{useState, useEffect} from "react";
import "../../styles/editModal.css";
import { useHttp } from "../../sevices/useHttp";
import { baseUrl } from "../../sevices/API_URL";
import Select from "react-select";

export default function EditModalPch({id, setShowEditModal, showEditModal, getAllChildOrgPch}) {

  const {request} = useHttp()
  const [name, setName] = useState("")
  const [oneShch, setOneShch] = useState({})
  const [allStations, setAllStations] = useState([])

  let options = allStations?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const [selected, setSelected] = useState([]);

const onChange = (selectedOptions) => {
  setSelected(selectedOptions);
};

 // Get Station list----------------------------------------------------

useEffect(() => {
    request(`${baseUrl}/api/station/find/list`, "GET", null, {
        "Authorization" : `Bearer ${localStorage.getItem("token")}`,
        "Content-type" : "application/json"
    })
    .then(response => {
        setAllStations(response?.data)
    })
    .catch((err) => {
        console.log(err);
    })
}, [request]) 


  // EDIT Pch========================================================================
  const onEdit = () => {
    const station_list = selected?.map((user) => user?.value);
    const editedOrg = {id, name, station_list}
    request(`${baseUrl}/api/pch-organization/edit`, "PUT", JSON.stringify(editedOrg),{
        "Authorization" : `Bearer ${localStorage.getItem("token")}`,
        "Content-type" : "application/json"
    }).then(response => {
        getAllChildOrgPch()
    }).catch((err) => {
        console.log(err);
    })
    setShowEditModal(!showEditModal)
};

// Get one Pch=========================================================================
useEffect(() => {
  request(`${baseUrl}/api/pch-organization/view/${id}`, "GET", null, {
    "Authorization" : `Bearer ${localStorage.getItem("token")}`,
    "Content-type" : "application/json"
})
.then(response => {
    setOneShch(response?.data)
})
.catch((err) => {
    console.log(err);
})
}, [request, id]) 
//initial value of inputs =======================================================================

let oldValue = oneShch?.org_list?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
useEffect(() => {
  if(oneShch){
    setName(oneShch?.name)
  }
  if(oldValue){
    setSelected(oldValue);
  }
}, [oneShch]) 



  return (
    <div className="edit_modal_wrapper">
      <div class="modal-container"></div>
      <div className="edit_input_group">
        <h3 className="edit_modal_title">Tahrirlash!</h3>
        <div className="edit_input_group_wrapper">
          <div className="inputbox">
            <input 
            type="text" 
            required="required"
            value={name}
            onChange={(e) => setName(e.target.value)}
            />
            <span>Nomi</span>
          </div>
          <div className="inputbox">
          <Select
            className="multi_select"
            useDragHandle
            axis="xy"
            distance={4}
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            isMulti
            options={options}
            value={selected}
            onChange={onChange}
            closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className="edit_modal_button_wrapper">
          <div class="center">
            <div class="outer button">
              <button onClick={() => setShowEditModal(!showEditModal)}>Yopish</button>
              <span></span>
              <span></span>
            </div>
            <div class="outer button">
              <button onClick={onEdit}>Saqlash</button>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
