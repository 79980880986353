import Navigation from "./Navigation.js";
import { Col, Container, Row } from "@nextui-org/react";

export const Layout = ({ children, role }) => {

  const menus = {
    ROLE_ADMIN:  [
      {  path: '/superAdminProfile', name: 'Profile' },
      {  path: '/superAdminAdmins', name: 'Adminlar' },
      {  path: '/superAdminOrg', name: 'Organization' },
      {  path: '/superAdminModerators', name: 'Moderatorlar' },
    ],
    ROLE_MODERATOR: [
      {path: '/moderatorProfile', name: 'Profile' },
      {path: '/moderatorStation', name: 'Stansiyalar' },
      {path: '/moderatorChildOrg', name: 'SHCH lar' },
      {path: '/moderatorChildOrgPch', name: 'PCH lar' },
      {path: '/moderatorShChAdmin', name: 'SHCH Admin' },
      {path: '/moderatorPChAdmin', name: 'PCH Admin' },
    ],
    ROLE_USER_PCH: [
      { path: '/adminForPch', name: 'Profile' },
      { path: '/employeesForPch', name: 'Ishchilar' },
      { path: '/izostikForPch', name: 'Izostiklar' },
    ],
    ROLE_EMPLOYEE_PCH: [
      { path: '/employeePchProfile', name: 'Profile' },
      { path: '/employeepchWorkDone', name: 'Bajarilgan ishlar' },
    ],
    ROLE_USER: [
      { path: '/adminForShch', name: 'Profile' },
      { path: '/employeesForShch', name: 'Ishchilar' },
      { path: '/izostikForShch', name: 'Izostik' },
    ],
    ROLE_EMPLOYEE: [
      { path: '/employeeshchProfile', name: 'Profile' },
      { path: '/employeeshchWorkDone', name: 'Bajarilgan ishlar' },
    ],
  }
  return (
  <div>
    <Navigation menus={menus[role]} />
    <Container>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
  </div>)
}