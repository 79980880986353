import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  isAuthenticated: false, // Royxatdan otmagan
  isFetched: true, // Loader
  token: localStorage.getItem('token'),
  role: "",
  loggedInPeople:'',
  avatarUrl:'',
  name:'',
}

export const loginSlice = createSlice({
  name:"logins",
  initialState,
  reducers : {
    nameOfloggedInPeople: (state,{payload}) => {
      state.loggedInPeople = payload;
    },
    requestLogin: (state) => {
      state.isFetched = false;
    },
    successLogin: (state, {payload}) => {
      localStorage.setItem("token", payload);
      state.isFetched = true;
      state.isAuthenticated = true;
      state.token = payload;
    },
    errorLogin: (state) => {
      state.isFetched = true;
    },
    requestProfile: (state) => {
      state.isFetched = false;
    },
    successProfile: (state, {payload}) => {
      state.isFetched = true;
      state.isAuthenticated = true;
      state.role = payload;
    },
    errorProfile: (state) => {
      state.isFetched = true;
      state.isAuthenticated = false;
    },
    logout: (state) => {
      localStorage.removeItem('token');
      state.token = null;
      state.isAuthenticated = false;
    },
    avatarImg: (state, {payload}) => {
      state.avatarUrl = payload;
    }, 
    userNameEmp: (state, {payload}) => {
      state.name = payload;
    },
    successFirebase: (state, {payload}) => {
      localStorage.setItem("tokenFirebase", payload);
    },    
  }
})

const {actions, reducer} = loginSlice;

export default reducer;
export const { requestLogin, successLogin, errorLogin, requestProfile, successProfile, errorProfile,nameOfloggedInPeople, logout, avatarImg, userNameEmp, successFirebase } = actions;