import React,{useState, useEffect} from "react";
import "../../styles/editModal.css";
import { useHttp } from "../../sevices/useHttp";
import { baseUrl } from "../../sevices/API_URL";

export default function EditModalModerator({id, setShowEditModal, showEditModal, getAllModerators}) {

  const {request} = useHttp()
  const [full_name, setFull_name] = useState("")
  const [password, setPassword] = useState("")
  const [username, setUsername] = useState("")
  const [oneModerator, setOneModerator] = useState({})


  // Get one Moderator=========================================================================
useEffect(() => {
    request(`${baseUrl}/api/moderator/find/by-id/${id}`, "GET", null, {
      "Authorization" : `Bearer ${localStorage.getItem("token")}`,
      "Content-type" : "application/json"
  })
  .then(response => {
      setOneModerator(response?.data)
  })
  .catch((err) => {
      console.log(err);
  })
  }, [request, id]) 
  
  useEffect(() => {
    if(oneModerator){
      setFull_name(oneModerator?.full_name)
      setUsername(oneModerator?.username)
    }
  }, [oneModerator]) 
  

  // EDIT Moderator========================================================================
  const onEdit = () => {

    const org_id = oneModerator?.organization?.id

    const editedModerator = {id, full_name, username, org_id, password}
    request(`${baseUrl}/api/moderator/edit`, "PUT", JSON.stringify(editedModerator),{
        "Authorization" : `Bearer ${localStorage.getItem("token")}`,
        "Content-type" : "application/json"
    }).then(response => {
        getAllModerators()
    }).catch((err) => {
        console.log(err);
    })
    setShowEditModal(!showEditModal)
};


  return (
    <div className="edit_modal_wrapper">
      <div class="modal-container"></div>
      <div className="edit_input_group">
        <h3 className="edit_modal_title">Tahrirlash!</h3>
        <div className="edit_input_group_wrapper">
          <div className="inputbox">
            <input 
            type="text" 
            required="required"
            value={full_name}
            onChange={(e) => setFull_name(e.target.value)}
            />
            <span>F.I.SH</span>
          </div>
          <div className="inputbox">
            <input 
            type="text" 
            required="required"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            />
            <span>Username</span>
          </div>
          <div className="inputbox">
            <input 
            type="text" 
            required="required" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            />
            <span>Paroli</span>
          </div>
        </div>
        <div className="edit_modal_button_wrapper">
          <div class="center">
            <div class="outer button">
              <button onClick={() => setShowEditModal(!showEditModal)}>Yopish</button>
              <span></span>
              <span></span>
            </div>
            <div class="outer button">
              <button onClick={onEdit}>Saqlash</button>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
