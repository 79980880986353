import React, { useEffect, useState } from "react";
import { useHttp } from "../../sevices/useHttp";
import { baseUrl } from "../../sevices/API_URL";
import ModeratorEmpStatistics from "../statisticsPages/moderator/ModeratorEmpStatistics";
import { Container } from "@nextui-org/react";
import ModeratorStikStatistics from "../statisticsPages/moderator/ModeratorStikStatistics";
import LoadingPage from "../../loading/LoadingPage";



export default function ModeratorProfile() {
  const { request } = useHttp();
  const [statistics, setStatistics] = useState({});
  const [status, setStatus] = useState(false);

  const numberOfStiks = statistics?.sticks?.length ? statistics?.sticks.map((item) => (item.progress_works + item.red + item.white + item.yellow)) : 0
  let sum = numberOfStiks?.length ? numberOfStiks.reduce(function(a, b){
    return a + b;
  }) : 0;

  useEffect(() => {
    request(`${baseUrl}/api/statistic/find/all`, "GET", null, {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-type": "application/json",
    })
      .then((response) => {
        setStatistics(response?.data);
        setStatus(response.ok)
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [request]);

        if(status){
            return(
                <Container lg>
      <div className="statistics_wrapper">
        <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3
                style={{ color: "rgb(244, 161, 0)" }}
                className="Statistics_count_number"
              >
                {statistics?.shchs}
              </h3>
              <h4 className="Statistics_count_desc">Barcha SHCHlar soni</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <i
                style={{ color: "#FCE3B3" }}
                className="fa-solid fa-landmark fa-2x"
              ></i>
            </div>
          </div>
          <div
            style={{ backgroundColor: "rgb(244, 161, 0)" }}
            className="statistics_card_footer"
          >
            <p className="statistics_card_footer_text">
                MTU miqyosida
            </p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark"></i>
            </div>
          </div>
        </div>

        <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3
                style={{ color: "rgb(255, 65, 58)" }}
                className="Statistics_count_number"
              >
                {statistics?.pchs}
              </h3>
              <h4 className="Statistics_count_desc">Barcha PCH lar soni</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <i
                style={{ color: "#FFC6C4" }}
                className="fa-solid fa-landmark fa-2x"
              ></i>
            </div>
          </div>
          <div
            style={{ backgroundColor: "rgb(255, 65, 58)" }}
            className="statistics_card_footer"
          >
            <p className="statistics_card_footer_text">
                MTU miqyosida
            </p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>

        <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3
                style={{ color: "rgb(0, 172, 105)" }}
                className="Statistics_count_number"
              >
                {statistics?.stations}
              </h3>
              <h4 className="Statistics_count_desc">Barcha stansiyalar soni</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <i
                style={{ color: "#B3E6D2" }}
                className="fa-solid fa-landmark fa-2x"
              ></i>
            </div>
          </div>
          <div
            style={{ backgroundColor: "rgb(0, 172, 105)" }}
            className="statistics_card_footer"
          >
            <p className="statistics_card_footer_text">
                MTU miqyosida
            </p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>

        <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3
                style={{ color: "rgb(51, 102, 255)" }}
                className="Statistics_count_number"
              >
                {sum}
              </h3>
              <h4 className="Statistics_count_desc">
                Barcha izostiklar soni
              </h4>
            </div>
            <div className="statistics_icon_wrapper">
              <i
                style={{ color: "#C2D1FF" }}
                className="fa-solid fa-landmark fa-2x"
              ></i>
            </div>
          </div>
          <div
            style={{ backgroundColor: "rgb(51, 102, 255)" }}
            className="statistics_card_footer"
          >
            <p className="statistics_card_footer_text">
                MTU miqyosida
            </p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>
      </div>

      <ModeratorStikStatistics statistics={statistics} /> 
      <ModeratorEmpStatistics statistics={statistics} /> 
    </Container>
            )
        }else{
            return(
                <LoadingPage/>
            )
        }
}
