import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Text, Container } from "@nextui-org/react";
import { UserName } from "../superAdmin_AddAdmin/UserName";
import { Password } from "../superAdmin_AddAdmin/Password";
import { FullName } from "../superAdmin_AddAdmin/FullName";
import { useHttp } from "../../../sevices/useHttp";
import {API_URL_get_org_list} from "../../../sevices/API_URL"

export default function AddModeratorModal(props) {

    const { visible, closeHandler, onSubmit } = props
    const {request} = useHttp()

    const [password, setPassword] = useState("")
    const [username, setUsername] = useState("")
    const [full_name, setFull_name] = useState("")
    const [org_id, setOrg_id] = useState("")
    const [allOrgList, setAllOrgList] = useState([])


    const handlerSubmit = () => {
        const obj = {
            full_name,
            username,
            password,
            org_id
        }
        onSubmit(obj)
        closeHandler()
        setAllOrgList([])
        setFull_name("")
        setUsername("")
        setPassword("")
    };

    const gettAllOrganization = () => {
        request(`${API_URL_get_org_list}`, "GET", null, {
            "Authorization" : `Bearer ${localStorage.getItem("token")}`,
            "Content-type" : "application/json"
        })
        .then(response => {
            setAllOrgList(response.data)
        })
        .catch((err) => {
            console.log(err);
        })
    }
    
    useEffect(() => {
        gettAllOrganization()
    }, []) 


    return (
        <Container lg>
            <Modal
                closeButton
                blur
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
            >
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        Moderator Qo'shish
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        value={full_name}
                        onChange={e => setFull_name(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Full Name"
                        contentLeft={<FullName fill="currentColor" />}
                    />
                    <Input
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="User Name"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                    <Input
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Password"
                        contentLeft={<Password fill="currentColor" />}
                    />
                    <select className="form-select" aria-label="Default select example" value={org_id} onChange={e => setOrg_id(e.target.value)}>
                        <option selected>MTUni tanlang</option>
                        {allOrgList?.length ? allOrgList.map((item, index) => (
                            <option value={item.id}>{item.name}</option>
                        )) : null}
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat color="error" onClick={closeHandler}>
                        Bekor qilish
                    </Button>
                    <Button auto onClick={handlerSubmit}>
                        Saqlash
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}