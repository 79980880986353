import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allEmployeesShch:[],
    all_Izostik:[],
}

export const admin_shchSlice = createSlice({
    name: "shch_admins",
    initialState,
    reducers: {
        allEmployeesShchList: (state, { payload }) => {
            state.allEmployeesShch = payload
        },
        deletedEmployeesShch: (state, { payload }) => {
            state.allEmployeesShch = state.allEmployeesShch.filter(element => element.id !== payload)
        },
        allIzostik: (state, { payload }) => {
            state.all_Izostik = payload
        },
        deletedIzostik: (state, { payload }) => {
            state.all_Izostik = state.all_Izostik.filter(element => element.id !== payload)
        },
    }
})

const { actions, reducer } = admin_shchSlice;

export default reducer;
export const { allEmployeesShchList, deletedEmployeesShch, deletedIzostik, allIzostik} = actions;