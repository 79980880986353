import React from "react";
import "../statistics.css";
import { Container, Spacer } from "@nextui-org/react";
import SuperAdminEmpStatistics from "./SuperAdminEmpStatistics";

export default function SuperAdminStatistics({statistics}) {

  return (
    <>
    <Container lg>
      <div className="statistics_wrapper">

      <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3 style={{color:"#3366FF"}} className="Statistics_count_number">{statistics?.white}</h3>
              <h4 className="Statistics_count_desc">Muddati o'tmaganlar</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <img src={require('../../../assets/Vectoryaqinlawmagan.png')} alt={"lock images"} className="img-fluid rounded "  />
            </div>
          </div>
          <div style={{backgroundColor:"#3366FF"}} className="statistics_card_footer">
            <p className="statistics_card_footer_text">Barcha MTU lar miqyosida</p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>

        <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3 style={{color:"rgb(244, 161, 0)"}} className="Statistics_count_number">{statistics?.yellow}</h3>
              <h4 className="Statistics_count_desc">Muddati yaqinlashgan</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <img src={require('../../../assets/Groupyaqinlawganlar.png')} alt={"lock images"} className="img-fluid rounded" />
            </div>
          </div>
          <div  style={{backgroundColor:"rgb(244, 161, 0)"}} className="statistics_card_footer">
            <p className="statistics_card_footer_text">Barcha MTU lar miqyosida</p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark"></i>
            </div>
          </div>
        </div>

      <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3 style={{color:"rgb(255, 65, 58)"}} className="Statistics_count_number">{statistics?.red}</h3>
              <h4 className="Statistics_count_desc">Muddati o'tganlar</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <img src={require('../../../assets/Groupmuddatiotganlar.png')} alt={"lock images"} className="img-fluid rounded" />
            </div>
          </div>
          <div style={{backgroundColor:"rgb(255, 65, 58)"}} className="statistics_card_footer">
            <p className="statistics_card_footer_text">Barcha MTU lar miqyosida</p>
            <div className="statistics_footer_icon_wrapper">
              <i  className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>
        

        <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3 style={{color:"#30D096"}} className="Statistics_count_number">{statistics?.works}</h3>
              <h4 className="Statistics_count_desc">Tugallangan ishlar soni</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <img src={require('../../../assets/iwlarolibborilayotgan.png')} alt={"lock images"} className="img-fluid rounded filter_img_green" />
            </div>
          </div>
          <div style={{backgroundColor:"#30D096"}} className="statistics_card_footer">
            <p className="statistics_card_footer_text">Barcha MTU lar miqyosida</p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>

        
        <div className="statistics_card_wrapper">
          <div className="statistics_card">
            <div className="statistics_count">
              <h3 style={{color:"#D6009A"}} className="Statistics_count_number">{statistics?.progress_works}</h3>
              <h4 className="Statistics_count_desc">Tugallanmagan ishlar soni</h4>
            </div>
            <div className="statistics_icon_wrapper">
              <img src={require('../../../assets/yakunlanmaganiwlar.png')} alt={"lock images"} className="img-fluid rounded " />
            </div>
          </div>
          <div style={{backgroundColor:"#D6009A"}} className="statistics_card_footer">
            <p className="statistics_card_footer_text">Barcha MTU lar miqyosida</p>
            <div className="statistics_footer_icon_wrapper">
              <i className="fa-solid fa-landmark "></i>
            </div>
          </div>
        </div>
      </div>

      
      <Spacer y={1}/>
      <SuperAdminEmpStatistics statistics={statistics}/>
    </Container>
    </>
  );
}
