import React, {useEffect, useState} from 'react'
import LoadingPage from '../../loading/LoadingPage'
import { baseUrl } from '../../sevices/API_URL'
import { useHttp } from '../../sevices/useHttp'
import StatisticsforSHCHadmin from '../statisticsPages/shchadmin/StatisticsforSHCHadmin'



export default function Admin_SHCH_Profile() {

  const {request} = useHttp()
  const [statistics, setStatistics] = useState({})
  const [status, setStatus]  = useState("")

  useEffect(() => {
    request(`${baseUrl}/api/statistic/find/all`, "GET", null,{
        "Authorization" : `Bearer ${localStorage.getItem("token")}`,
        "Content-type" : "application/json"
    }).then(response => {
        setStatistics(response?.data)
        setStatus(response?.status)
    }).catch((err) => {
        console.log(err);
    })
}, [request])


  return (
    <>
      {status ? <StatisticsforSHCHadmin statistics = {statistics}/> : <LoadingPage/>}
    </>
  )
}
