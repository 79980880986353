import { useEffect } from 'react';
import {Routes, Route} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate, Navigate} from "react-router-dom"

import { Layout } from './Layout';
import { NextUIProvider } from '@nextui-org/react';
import { requestProfile, successProfile, errorProfile, avatarImg, userNameEmp, successFirebase, logout} from "./redux/loginSlice"
import Login from './components/loginPage/Login';
import SuperAdmin from "./components/superAdmin/superAdminAdmins/SuperAdmin"
import { useHttp } from './sevices/useHttp';
import SuperAdminProfile from "./components/superAdmin//SuperAdminProfile"
import SuperAdminOrganization from './components/superAdmin/superAdminOrganization/SuperAdminOrganization';
import SuperAdminModerator from "./components/superAdmin/superAdminModerator/SuperAdminModerator"
import ModeratorProfile from './components/moderator/ModeratorProfile';
import Station from './components/moderator/moderatorStation/Station';
import ChildOrg from "./components/moderator/moderatorChildOrganization/ChildOrg"
import SinglieChildOrgInfo from './components/moderator/moderatorChildOrganization/SinglieChildOrgInfo';
import ChildOrgPch from "./components/moderator/moderatorPCH/ChildOrgPch"
import SinglieChildOrgPchInfo from './components/moderator/moderatorPCH/SingleChildOrgPchInfo';
import ShCh_Admin from './components/moderator/moderatorShchAdmin/ShCh_Admin';
import PCH_Admin from './components/moderator/ModeratorPCHAdmin/PCH_Admin';
import Admin_PCH_Profile from './components/Admin_PCH/Admin_PCH_Profile';
import Employees_PCH from './components/Admin_PCH/Employees_PCH';
import Izostik_PCH from './components/Admin_PCH/Izostik_PCH';
import Single_izostik_info from './components/Admin_PCH/Single_izostik_info'; 
import Employee_Pch_Profile from './components/employeePCH/Employee_Pch_Profile';
import Admin_SHCH_Profile from "./components/adminShch/Admin_SHCH_Profile"
import Epmloyees_SHCH from './components/adminShch/Epmloyees_SHCH';
import Izostik_SHCH from './components/adminShch/Izostik_SHCH';
import Employee_SHCH_profile from './components/employeeSHCH/Employee_SHCH_profile';
import WorkDone from './components/employeeSHCH/WorkDone';
import IzostikWhite from './components/employeeSHCH/IzostikWhite';
import { baseUrl } from './sevices/API_URL';

//firebase===================================================
import { messaging } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {

  const { isAuthenticated, token, role } = useSelector(state => state.logins)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {request} = useHttp()



  
  //====================================================FIREBASE==============================

  function showToast(text) {
    toast(text);
  }
  async function requestPermission() {
    const permissions = await Notification.requestPermission()
    if (permissions === "granted") {
        console.log("granted")
        const token = await getToken(messaging, {vapidKey: 'BAXcCQs5zgvctGOIqCl9fzMJdRfvuKVW2sST9dspdIaAIqRAjWsNNncOjDbeIHfMcB-yD7NsePUIJNIbW6FcU8c'})
        dispatch(successFirebase(token))
        console.log("token ", token)
    } else if (permissions === "denied") {
        console.log("denied")
        alert("denied")
    }
}

  onMessage(messaging, (payload) => {
    let title = payload?.notification?.title;
    showToast(title);
  });

 //====================================================FIREBASE==============================

  //----------------------------Authentication Start --------------------------------------
  useEffect(() => {
    if (token) {
      dispatch(requestProfile());
      request(`${baseUrl}/api/user/me`, "GET", null, {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json"
      }).then(response => {
        if(response?.ok === true){
          dispatch(successProfile(response?.data?.role))
          dispatch(avatarImg(response?.data?.__file__?.url_1))
          dispatch(userNameEmp(response?.data?.full_name))
        }else{
          dispatch(logout())
        }
      })
        .catch(error => {
          dispatch(errorProfile());
        });
    } else {
      navigate('/login');
    }
  }, [token, request])

  useEffect(() => {
    requestPermission().then((r) => console.log("ishladi *********************************** "));
  }, []);


  // ---------------------------Authentication End ------------------------------------------------


  if (!isAuthenticated) {
    return (
      <div className="main-section">
        <NextUIProvider>
          <Routes>
            <Route path="/login" element={<Login/>} />
          </Routes>
        </NextUIProvider>
      </div>
    )
  }

  if (role === "ROLE_ADMIN") {
  return (
    <NextUIProvider>
      <ToastContainer/>
      <Layout role={role}>
        <Routes>
          <Route path='/superAdminProfile' element={<SuperAdminProfile/>}/>
          <Route path='/superAdminAdmins' element={<SuperAdmin/>}/>
          <Route path='/superAdminOrg' element={<SuperAdminOrganization/>}/>
          <Route path='/superAdminModerators' element={<SuperAdminModerator/>}/>
          <Route path="*" element={<Navigate to="/superAdminProfile" replace />}/>
        </Routes>
      </Layout>
    </NextUIProvider>
  );
}
if (role === "ROLE_MODERATOR") {
  return (
    <NextUIProvider>
      <ToastContainer/>
      <Layout role={role}>
        <Routes>
          <Route path='/moderatorProfile' element={<ModeratorProfile/>}/>
          <Route path='/moderatorStation' element={<Station/>}/>
          <Route path='/moderatorChildOrg' element={<ChildOrg/>}/>
          <Route path='/moderatorChildOrgPch' element={<ChildOrgPch/>}/>
          <Route path='/moderatorShChAdmin' element={<ShCh_Admin/>}/>
          <Route path='/moderatorPChAdmin' element={<PCH_Admin/>}/>
          <Route path='/singleChildOrg/:id' element={<SinglieChildOrgInfo/>}/>
          <Route path='/singleChildOrgPch/:id' element={<SinglieChildOrgPchInfo/>}/>
          <Route path="*" element={<Navigate to="/moderatorProfile" replace />}/>
        </Routes>
      </Layout>
    </NextUIProvider>
  );
}

if (role === "ROLE_USER_PCH") {
  return (
    <NextUIProvider>
      <ToastContainer/>
      <Layout role={role}>
        <Routes>
          <Route path='/adminForPch' element={<Admin_PCH_Profile/>}/>
          <Route path='/employeesForPch' element={<Employees_PCH/>}/>
          <Route path='/izostikForPch' element={<Izostik_PCH/>}/>
          <Route path='/singleIzostikInfo/:id' element={<Single_izostik_info/>}/>
          <Route path="*" element={<Navigate to="/adminForPch" replace />}/>
        </Routes>
      </Layout>
    </NextUIProvider>
  );
}

if (role === "ROLE_EMPLOYEE_PCH") {
  return (
    <NextUIProvider>
      <ToastContainer/>
      <Layout role={role}>
        <Routes>
          <Route path='/employeePchProfile' element={<Employee_Pch_Profile/>}/>
          <Route path='/employeepchWorkDone' element={<WorkDone/>}/>
          <Route path='/employeePchProfile/whiteStik' element={<IzostikWhite/>}/>
          <Route path="*" element={<Navigate to="/employeePchProfile" replace />}/>
        </Routes>
      </Layout>
    </NextUIProvider>
  );
}

if (role === "ROLE_USER") {
  return (
    <NextUIProvider>
      <ToastContainer/>
      <Layout role={role}>
        <Routes>
          <Route path='/adminForShch' element={<Admin_SHCH_Profile/>}/>
          <Route path='/employeesForShch' element={<Epmloyees_SHCH/>}/>
          <Route path='/izostikForShch' element={<Izostik_SHCH/>}/>
          <Route path='/singleIzostikInfo/:id' element={<Single_izostik_info/>}/>
          <Route path="*" element={<Navigate to="/adminForShch" replace />}/>
        </Routes>
      </Layout>
    </NextUIProvider>
  );
}

if (role === "ROLE_EMPLOYEE") {
  return (
    <NextUIProvider>
      <ToastContainer/>
      <Layout role={role}>
        <Routes>
          <Route path='/employeeshchProfile' element={<Employee_SHCH_profile/>}/>
          <Route path='/employeeshchWorkDone' element={<WorkDone/>}/>
          <Route path='/employeePchProfile/whiteStik' element={<IzostikWhite/>}/>
          <Route path="*" element={<Navigate to="/employeeshchProfile" replace />}/>
        </Routes>
      </Layout>
    </NextUIProvider>
  );
}




}

export default App;