import React,{useState} from "react";
import { Modal, Input, Button, Text } from "@nextui-org/react";
import { UserName } from "../../superAdmin/superAdmin_AddAdmin/UserName";

export default function AddStationModal(props) {
    const {visible, closeHandler, onSubmit} = props

    const [name, setName] = useState("")

    const handlerSubmit = () => {
        const obj = {
            name,
        }
        onSubmit(obj)
        closeHandler()
        setName("")
    };

    return (
        <div>
            <Modal
                closeButton
                blur
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
            >
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        Stansiya yaratish
                    </Text>
                </Modal.Header>
                <Modal.Body>
                <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Organization Name"
                        contentLeft={<UserName fill="currentColor" />}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat color="error" onClick={closeHandler}>
                        Bekor qilish
                    </Button>
                    <Button auto onClick={handlerSubmit}>
                        Saqlash
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}