import React,{useState, useEffect} from "react";
import "../../styles/editModal.css";
import { useHttp } from "../../sevices/useHttp";
import { baseUrl } from "../../sevices/API_URL";

export default function EditModalAdminPch({id, setShowEditModal, showEditModal, gettAllAdmins}) {

  const {request} = useHttp()
  const [full_name, setFull_name] = useState("")
  const [password, setPassword] = useState("")
  const [username, setUsername] = useState("")
  const [job_name, setJob_name] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [oneShchAdmoin, setOneShchAdmoin] = useState({})

  // Get one AdminPch=========================================================================
useEffect(() => {
    request(`${baseUrl}/api/admin-pch/find/by-id/${id}`, "GET", null, {
      "Authorization" : `Bearer ${localStorage.getItem("token")}`,
      "Content-type" : "application/json"
  })
  .then(response => {
    setOneShchAdmoin(response?.data)
    console.log(response);
  })
  .catch((err) => {
      console.log(err);
  })
  }, [request, id]) 
  // get initial value===================================================================
  useEffect(() => {
    if(oneShchAdmoin){
      setFull_name(oneShchAdmoin?.full_name)
      setUsername(oneShchAdmoin?.username)
      setPhone_number(oneShchAdmoin?.phone_number)
      setJob_name(oneShchAdmoin?.job_name)
    }
  }, [oneShchAdmoin]) 
  

  // EDIT PchAdmin========================================================================
const onEdit = () => {

    const org_id = oneShchAdmoin?.organization?.id

    const editedAdmin = {id, full_name,password,username, phone_number,job_name, org_id }
    request(`${baseUrl}/api/admin-pch/edit`, "PUT", JSON.stringify(editedAdmin),{
        "Authorization" : `Bearer ${localStorage.getItem("token")}`,
        "Content-type" : "application/json"
    }).then(response => {
        gettAllAdmins()
    }).catch((err) => {
        console.log(err);
    })
    setShowEditModal(!showEditModal)
};




  return (
    <div className="edit_modal_wrapper">
      <div class="modal-container"></div>
      <div className="edit_input_group">
        <h3 className="edit_modal_title">Tahrirlash!</h3>
        <div className="edit_input_group_wrapper">
          <div className="inputbox">
            <input 
            type="text" 
            required="required"
            value={full_name}
            onChange={(e) => setFull_name(e.target.value)}
            />
            <span>F.I.SH</span>
          </div>
          <div className="inputbox">
            <input 
            type="text" 
            required="required"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            />
            <span>Username</span>
          </div>
          <div className="inputbox">
            <input 
            type="text" 
            required="required" 
            value={job_name}
            onChange={(e) => setJob_name(e.target.value)}
            />
            <span>Kasbi</span>
          </div>
          <div className="inputbox">
            <input 
            type="text" 
            required="required" 
            value={phone_number}
            onChange={(e) => setPhone_number(e.target.value)}
            />
            <span>Telefon raqami</span>
          </div>
          <div className="inputbox">
            <input 
            type="text" 
            required="required" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            />
            <span>Paroli</span>
          </div>
        </div>
        <div className="edit_modal_button_wrapper">
          <div class="center">
            <div class="outer button">
              <button onClick={() => setShowEditModal(!showEditModal)}>Yopish</button>
              <span></span>
              <span></span>
            </div>
            <div class="outer button">
              <button onClick={onEdit}>Saqlash</button>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
