import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



export default function SuperAdminEmpStatistics ({statistics}) {

    const datapch = statistics?.arrPch
    const datashch = statistics?.arrShch


    const dataStatisPch = datapch?.map((item, index, arr) => {
        return(
            {name:item.name, Xodimlar:arr[index].employeeCount}
        )
    })
    
    const dataStatisShch = datashch?.map((item, index, arr) => {
        return(
            {name:item.name, Xodimlar:arr[index].employeeCount}
        )
    })
    
    return (
    <div>
        <h3 className='text-center my-3'>MTU lardagi barcha PCH ishchilari haqida ma'lumot</h3>
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={500}
                height={300}
                data={dataStatisPch}
                margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            barSize={20}
            >
            <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="Xodimlar" fill="#8884d8" background={{ fill: '#eee' }} />
            </BarChart>
        </ResponsiveContainer>

        <h3 className='text-center my-3'>MTU lardagi barcha SHCH ishchilari haqida ma'lumot</h3>

        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={500}
                height={300}
                data={dataStatisShch}
                margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            barSize={20}
            >
            <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="Xodimlar" fill="rgb(186,58,202)" background={{ fill: '#eee' }} />
            </BarChart>
        </ResponsiveContainer>
    </div>
    );
}
