import React,{useState} from "react";
import { Navbar, Text, Dropdown, Avatar } from "@nextui-org/react";
import { AcmeLogo } from "./AcmeLogo";
import { logout } from "./redux/loginSlice";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { NavLink } from "react-router-dom";
import "./styles/navigation.css";
import ChangePassword from "./components/changePsswordModal/ChangePassword";
import ChangeAvatar from "./components/changePsswordModal/ChangeAvatar";
import { baseUrl } from "./sevices/API_URL";

function Navigation({ menus }) {

  const dispatch = useDispatch();
  const {avatarUrl, name} = useSelector(state => state.logins)
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);

  return (
    <>
    {visible ? <ChangePassword visible={visible} setVisible={setVisible}/> : null}
    {visible1 ? <ChangeAvatar visible1={visible1} setVisible1={setVisible1}/> : null}
      <Navbar isBordered variant="sticky" className="navigation">
        <Navbar.Toggle showIn="xs" />
        <Navbar.Brand
          css={{
            "@xs": {
              w: "12%",
            },
          }}
        >
          <AcmeLogo />
          <Text b color="inherit" hideIn="xs">
            IZOSTIK
          </Text>
        </Navbar.Brand>
        <Navbar.Content
          enableCursorHighlight
          activeColor="secondary"
          hideIn="xs"
          variant="highlight-rounded"
        >
          {menus.map((item, index) => (
            <div style={{display:"flex", columnGap:"30px"}}>
                <NavLink
                className="nav_link"
                to={item.path}
                key={index}
                >
                  {item.name}
                </NavLink>
            </div>
          ))}
        </Navbar.Content>
        <Navbar.Content
          css={{
            "@xs": {
              w: "12%",
              jc: "flex-end",
            },
          }}
        >
          <Dropdown placement="bottom-right">
            <Navbar.Item>
              <Dropdown.Trigger>
                <Avatar
                  bordered
                  as="button"
                  color="secondary"
                  size="md"
                  src={`${baseUrl}/file${avatarUrl}`}
                />
              </Dropdown.Trigger>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label="User menu actions"
              color="secondary"
              onAction={(actionKey) => console.log({ actionKey })}
            >
              <Dropdown.Item key="profile" css={{ height: "$18" }}>
                <Text b color="inherit" css={{ d: "flex" }}>
                  Signed in as
                </Text>
                <Text b color="inherit" css={{ d: "flex" }}>
                  {name}
                </Text>
              </Dropdown.Item>
              <Dropdown.Item key="settings" withDivider>
              <button
                    className="logout-btn form-control"
                    onClick={() => setVisible(!visible)}
                  >
                    Parolni o'zgartirish
              </button>
              </Dropdown.Item>
              <Dropdown.Item key="team_settings" >
              <button
                    className="logout-btn form-control"
                    onClick={() => setVisible1(!visible1)}
                  >
                    Profil rasmini o'zgartirish
              </button>
              </Dropdown.Item>
              <Dropdown.Item key="logout" withDivider color="error">
                <form className="d-flex" role="search">
                  <button
                    className="logout-btn form-control"
                    onClick={() => dispatch(logout())}
                    type="submit"
                  >
                    <span>Log out</span>
                  </button>
                </form>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Content>
      </Navbar>
    </>
  );
}

export default Navigation;
